import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

// App Storage Labels
export const APP_BIOMETRICS_STORAGE_LABEL = 'biometrics';
export const APP_DARK_MODE_STORAGE_LABEL = 'darkMode';
export const APP_DEVICE_ID_STORAGE_LABEL = 'deviceId';
export const APP_PHONE_NUMBER_STORAGE_LABEL = 'phoneNumber';
export const APP_SERVER_TIME_GAP_STORAGE_LABEL = 'serverTimeGap';
export const APP_SESSION_END_TIME_STORAGE_LABEL = 'storageSessionEnd';
export const APP_USER_STORAGE_LABEL = 'user';
export const APP_UUID_STORAGE_LABEL = 'uuid';

// Mock Storage Labels
export const MOCK_IDENTITY_STORAGE_LABEL = 'mockIdentity';
export const MOCK_IDENTITY_DOCUMENT_VALIDATION_STORAGE_LABEL = 'mockIdentityDocumentValidation';
export const MOCK_LOGIN_PIN_STORAGE_LABEL = 'mockLoginPin';
export const MOCK_PROFILE_STORAGE_LABEL = 'mockProfile';
export const MOCK_USER_STORAGE_LABEL = 'mockUser';

export type DataKey = 'biometrics' |
  'biometricEnabled' |
  'darkMode' |
  'deviceId' |
  'enrollment' |
  'identityDocumentValidationStatus' |
  'mockIdentity' |
  'mockIdentityDocumentValidation' |
  'mockLoginPin' |
  'mockProfile' |
  'mockUser' |
  'phoneNumber' |
  'serverTimeGap' |
  'selectedUserGiftCard' |
  'selectedGiftCardBonus' |
  'storageSessionEnd' |
  'user' |
  'uuid';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _data: {[key: string]: any} = {};
  constructor() {
  }

  /**
   * Clear key value from memory
   * @param key name of key to clear value
   */
  clear(key: DataKey): void {
    delete this._data[key];
  }

  /**
   * Clears all local storage and memory keys
   */
  async clearAll(): Promise<void> {
    this._data = {};
    // we need to clear all except for uuid
    const { keys } = await Storage.keys();
    for (const key of keys) {
      if (key === APP_UUID_STORAGE_LABEL) {
        continue;
      }
      await Storage.remove({ key });
    }
  }

  /**
   * Clears key value from local storage and memory
   * @param key name of key to clear value from
   */
  async clearPersistent(key: DataKey): Promise<void> {
    await Storage.remove({ key });
    delete this._data[key];
  }

  /**
   * Gets data from memory
   * @param key name of key to get value from
   */
  get<T>(key: DataKey): T {
    return this._data[key];
  }

  /**
   * Gets data from local storage and updates memory value
   * @param key name of key to get value from
   */
  async getPersistent<T>(key: DataKey): Promise<T> {
    const result = await Storage.get({ key });
    if (!result.value) {
      return result.value as any;
    }
    const data = JSON.parse(result.value);
    this._data[key] = data[key];
    return data[key];
  }

  /**
   * Saves data into memory
   * @param key name of key to save value
   * @param data data that will be saved
   */
  set(key: DataKey, data: any): void {
    this._data[key] = data;
  }

  /**
   * Saves data into local storage and memory
   * @param key name of key to save value
   * @param data data that will be saved
   */
  async setPersistent(key: DataKey, data: any): Promise<void> {
    const rawData = {};
    rawData[key] = data;
    await Storage.set({ key, value: JSON.stringify(rawData) });
    this._data[key] = data;
  }
}
