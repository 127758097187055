import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { take } from 'rxjs/operators';

export type Translation = { key: string, data: object };

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _translations: {[key: string]: string} = {};

  constructor(
    private translateSvc: TranslateService
  ) {
  }

  changeLanguage(localeId: 'es-CL') {
    this.translateSvc.use(localeId);
    this._translations = {};
  }

  /**
   * Gets the translation representation of the requested key, also it can replace templated values
   * @param translationKey can be a string key or Translation which enables variable replacement
   */
  get(translationKey: string | Translation): string | Promise<string> {
    if (typeof translationKey === 'string') {
      if (this._translations[translationKey]) {
        return this._translations[translationKey];
      }
      return this.translateSvc.get(translationKey).pipe(take(1)).toPromise();
    }
    return this.translateSvc.get(translationKey.key, translationKey.data).pipe(take(1)).toPromise();
  }
}
