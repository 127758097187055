import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';

import { cfaSignInPhone, cfaSignInPhoneOnCodeReceived, cfaSignInPhoneOnCodeSent } from 'capacitor-firebase-auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, take, throttleTime } from 'rxjs/operators';

import { ENVIRONMENT } from '@app/config';
import { AuthServiceInterface, Session } from '@resources/auth/auth.service';
import { User } from '@resources/user/user.service';
import { AccountManagerService } from '@services/account-manager/account-manager.service';
import { AppVersionService } from '@services/app-version/app-version.service';
import { APP_DEVICE_ID_STORAGE_LABEL, APP_PHONE_NUMBER_STORAGE_LABEL, APP_SERVER_TIME_GAP_STORAGE_LABEL, DataService } from '@services/data/data.service';
import { DeviceService } from '@services/device/device.service';
import { NetworkService } from '@services/network/network.service';

const SESSION_TIME_MARGIN = 30000; // this margin is to emit signOut event some time before server session expires

const FIREBASE_PLUGIN = 'FirebasePlugin';
@Injectable()
export class AuthServiceFirebase implements AuthServiceInterface {
  private _confirmationResult: firebase.default.auth.ConfirmationResult;
  private _endSessionTimeout: any;
  private _idTokenResults: { app: firebase.default.auth.IdTokenResult; } | null;
  private _isEnrolled = false;
  private _isInitialized = false; // this variable is to check if we should emit onSignOut when app starts
  private _isNative: boolean;
  private _isSignedIn = false;
  private _onAutomaticSignIn = new EventEmitter() as EventEmitter<void>;
  private _onResumeSubscription: Subscription;
  private _onSignIn$ = new EventEmitter() as EventEmitter<void>;
  private _onSignOut$ = new EventEmitter() as EventEmitter<void>;
  private _onUnroll$ = new EventEmitter() as EventEmitter<void>;
  private _recaptchaVerifier: firebase.default.auth.RecaptchaVerifier;
  private _sessionStartedAt: Date;
  private _shouldRefreshSession = false;
  constructor(
    private accountManagerSvc: AccountManagerService,
    private afAuth: AngularFireAuth,
    private appVersionSvc: AppVersionService,
    private dataSvc: DataService,
    private deviceSvc: DeviceService,
    private http: HttpClient,
    private networkSvc: NetworkService,
    private platform: Platform
  ) {
    this._isNative = this.platform.is('capacitor');
    combineLatest([afAuth.idTokenResult]).subscribe(([appIdTokenResult]) => {
      this._idTokenResults = {
        app: appIdTokenResult
      };
    }, (error) => {
      console.error('Error in AuthServiceFirebase.constructor() at combineLatest([afAuth.idTokenResult])', error);
      this._idTokenResults = null;
    });
    this.afAuth.onIdTokenChanged(() => this._onIdTokenChanged(),
      (error) => console.error('Error in AuthServiceFirebase.constructor() at this.afAuth.auth.onIdTokenChanged()', error));
    this._onSignIn$.subscribe(() => {
      this._isSignedIn = true;
      this._onResumeSubscription = this.platform.resume.subscribe(async () => {
        try {
          const isSignedIn = await this.isSignedIn();
          if (!this._isInitialized || (!isSignedIn && this._isSignedIn)) {
            this._onSignOut$.emit();
          }
        } catch (error) {
          console.error('Error in AuthServiceFirebase.constructor() at platform.resume()', error);
          this._onSignOut$.emit();
          this._onResumeSubscription.unsubscribe();
        }
      }, (error) => console.error('Error in AuthServiceFirebase.constructor() at platform.resume()', error));
    });
    this._onSignOut$.subscribe(() => {
      if (this._onResumeSubscription) {
        this._onResumeSubscription.unsubscribe();
        this._onResumeSubscription = null;
      }
    });
    this.networkSvc.onDisconnect().subscribe(async () => {
      const isEnrolled = await this.isEnrolled();
      if (isEnrolled) {
        this._onSignOut$.emit();
      }
    }, (error) => console.error('Error in AuthServiceFirebase.constructor() at networkSvc.onDisconnect()', error));
  }

  async enroll(phoneNumber: string, code: string, wasResend: boolean): Promise<void> {
    let step = '';
    try {
      let userCredential;
      step = 'check-if-google-play-services-is-available';
      const googlePlayServicesAvailable = await this.deviceSvc.isGooglePlayServicesAvailable();
      if (googlePlayServicesAvailable && this.platform.is('capacitor')) {
        step = 'confirm-phone-auth-provider';
        const { verificationId } = this.dataSvc.get('enrollment');
        const authCredential = firebase.default.auth.PhoneAuthProvider.credential(verificationId, code);
        step = 'sign-in-with-credential';
        userCredential = await this.afAuth.signInWithCredential(authCredential).catch(async (error) => {
          if (error.code === 'auth/invalid-verification-code' && wasResend) {
            return null;
          }
          return Promise.reject(error);
        });
      } else {
        step = 'confirm-firebase-auth';
        userCredential = await this._confirmationResult.confirm(code).catch(async (error) => {
          if (error.code === 'auth/invalid-verification-code' && wasResend) {
            return null;
          }
          return Promise.reject(error);
        });
      }
      if (!userCredential) {
        step = 'confirm-phone-number-validation';
        const { token } = await this.http.put(`${ENVIRONMENT.appPlatform.apiUrl}phoneNumberValidations/${phoneNumber}`, { code }).pipe(take(1)).toPromise() as { token: string };
        step = 'sign-in-with-custom-token';
        await this.afAuth.signInWithCustomToken(token);
      }
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.enroll() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  getSession(): Observable<Session> {
    return combineLatest([this.afAuth.user, this.afAuth.idToken, this.afAuth.idTokenResult])
      .pipe(map(([user, appIdToken, appIdTokenResult]) => {
        return user ? {
          deviceId: appIdTokenResult ? appIdTokenResult.claims.device_id : null,
          sessionId: appIdTokenResult ? appIdTokenResult.claims.session_id : null,
          startedAt: this._sessionStartedAt,
          token: appIdToken,
          userId: appIdTokenResult ? appIdTokenResult.claims.app_user_id : null
        } : null;
      }));
  }

  hasConnection(): boolean {
    return this.networkSvc.hasConnection();
  }

  async isEnrolled(): Promise<boolean> {
    let step = '';
    try {
      let appIdTokenResult;
      if (this._idTokenResults) {
        appIdTokenResult = this._idTokenResults.app;
      } else {
        step = 'get-id-token-results';
        appIdTokenResult = await this.afAuth.idTokenResult.pipe(take(1)).toPromise();
      }
      step = 'get-device-id';
      const deviceId = await this.dataSvc.getPersistent<string>(APP_DEVICE_ID_STORAGE_LABEL);
      step = 'get-phone-number';
      const phoneNumber = await this.dataSvc.getPersistent<User>(APP_PHONE_NUMBER_STORAGE_LABEL);
      step = 'do-validations';
      return (!!(appIdTokenResult && appIdTokenResult.claims.device_id) || !!deviceId) && !!phoneNumber;
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.isEnrolled() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  async isSignedIn(forceRefreshToken = true): Promise<boolean> {
    if (!this.hasConnection()) {
      return Promise.resolve(false);
    }
    let step = '';
    try {
      step = 'refresh-id-token';
      await this._refreshIdTokens(forceRefreshToken);
      let appIdTokenResult;
      if (this._idTokenResults) {
        appIdTokenResult = this._idTokenResults.app;
      } else {
        step = 'get-id-token-results';
        appIdTokenResult = await this.afAuth.idTokenResult.pipe(take(1)).toPromise();
      }
      step = 'get-server-time-gap';
      const serverTimeGap = await this.dataSvc.getPersistent<number>(APP_SERVER_TIME_GAP_STORAGE_LABEL);
      if (!appIdTokenResult || !appIdTokenResult.claims.session_expires_at || !serverTimeGap) {
        return false;
      }
      const now = new Date().getTime();
      return appIdTokenResult.claims.session_expires_at - SESSION_TIME_MARGIN - now + serverTimeGap > 0;
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.isSignedIn() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  listenToOtp(): Observable<{
    code: 'user-automatic-sign-in' | 'verification-code-received';
    verificationCode?: string;
    verificationId?: string;
  }> {
    return new Observable((subscriber) => {
      this._onAutomaticSignIn.subscribe(() => {
        subscriber.next({
          code: 'user-automatic-sign-in'
        });
        subscriber.complete();
      });
      cfaSignInPhoneOnCodeReceived().subscribe(async (event: { verificationId: string, verificationCode: string }) => {
        subscriber.next({
          code: 'verification-code-received',
          verificationCode: event.verificationCode,
          verificationId: event.verificationId
        });
      }, (error) => subscriber.error(error));
    });
  }

  onSignIn(): EventEmitter<void> {
    return this._onSignIn$;
  }

  onSignOut(): EventEmitter<void> {
    return this._onSignOut$.pipe(throttleTime(5000)) as EventEmitter<void>;
  }

  onUnroll(): EventEmitter<void> {
    return this._onUnroll$;
  }

  registerDevice(phoneNumber: string, pin: string): Observable<string> {
    const subscriber = new BehaviorSubject<string>('getting-id-token');
    const result = new Promise(async () => {
      let step = '';
      try {
        step = 'get-id-token';
        const idToken = await this.afAuth.idToken.pipe(take(1)).toPromise();
        step = 'creating-device';
        subscriber.next('creating-device-on-server');
        const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
        const { code, customToken, id } = await this.http.post(`${ENVIRONMENT.appPlatform.apiUrl}devices`, {
          alias: this.deviceSvc.model,
          appVersion: this.appVersionSvc.getVersionNumber(),
          manufacturer: this.deviceSvc.manufacturer,
          model: this.deviceSvc.model,
          os: this.deviceSvc.os,
          password: pin,
          platform: this.deviceSvc.platform,
          serial: this.deviceSvc.serial,
          uuid: this.deviceSvc.uuid,
          version: this.deviceSvc.version
        }, { headers }).pipe(take(1)).toPromise() as any;
        step = 'sign-in-with-custom-token';
        subscriber.next(`finishing-authentication:${code}`);
        await this.afAuth.signInWithCustomToken(customToken);
        step = 'refresh-id-token';
        await this._refreshIdTokens(true);
        step = 'set-info-with-account-manager';
        await this._setInfoWithAccountManager(pin);
        subscriber.next('setting-up-device');
        await Promise.all([
          this.dataSvc.setPersistent(APP_DEVICE_ID_STORAGE_LABEL, id),
          this.dataSvc.setPersistent(APP_PHONE_NUMBER_STORAGE_LABEL, phoneNumber)
        ]);
        subscriber.next('ready');
        subscriber.complete();
      } catch (error) {
        console.error(`Error in AuthServiceFirebase.registerDevice() at step ${step}`, error);
        subscriber.error(error);
        subscriber.complete();
      }
    });
    return subscriber.asObservable();
  }

  async resendOtp(phoneNumber: string): Promise<void> {
    await this.http.post(`${ENVIRONMENT.appPlatform.apiUrl}phoneNumberValidations`, { phoneNumber }).pipe(take(1)).toPromise();
  }

  sendOtp(phoneNumber: string): Observable<{
    status: 'otp-sent' | 'waiting-recaptcha-user-interaction';
    verificationId?: string;
  }> {
    return new Observable((subscriber) => {
      this.deviceSvc.isGooglePlayServicesAvailable().then(async (googlePlayServicesAvailable) => {
        if (googlePlayServicesAvailable && this.platform.is('capacitor')) {
          cfaSignInPhone(phoneNumber).subscribe(() => {// this gets called when the user is automatically signed in
            this._onAutomaticSignIn.emit();
          }, (error) => subscriber.error(error));
          cfaSignInPhoneOnCodeSent().subscribe((verificationId) => {
            subscriber.next({
              status: 'otp-sent',
              verificationId
            });
            subscriber.complete();
          }, (error) => subscriber.error(error));
        } else {
          this.afAuth.signInWithPhoneNumber(phoneNumber, this._recaptchaVerifier).then((confirmationResult) => {
            this._confirmationResult = confirmationResult;
            subscriber.next({ status: 'otp-sent' });
            subscriber.complete();
          }).catch((error) => subscriber.error(error));
          setTimeout(() => subscriber.next({ status: 'waiting-recaptcha-user-interaction' }), 2000);
        }
      }).catch((error) => subscriber.error(error));
    });
  }

  setRecaptchaVerifierContainer(): void {
    this._recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });
  }

  async signIn(pin: string): Promise<void> {
    let step = '';
    try {
      step = 'get-session-info';
      const [appVersion, idToken, idTokenResult] = await Promise.all([
        this.appVersionSvc.getVersionNumber(),
        this.afAuth.idToken.pipe(take(1)).toPromise(),
        this.afAuth.idTokenResult.pipe(take(1)).toPromise(),
      ]);
      step = 'do-validations';
      if (!idTokenResult || !idTokenResult.claims || !idTokenResult.claims.device_id) {
        return Promise.reject({ code: 'no-device-info', message: 'Device info not found.' });
      }
      step = 'do-request';
      const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
      const { createdAt } = await this.http.post(ENVIRONMENT.appPlatform.apiUrl + 'sessions', {
        appVersion,
        manufacturer: this.deviceSvc.manufacturer,
        model: this.deviceSvc.model,
        os: this.deviceSvc.os,
        password: pin,
        platform: this.deviceSvc.platform,
        serial: this.deviceSvc.serial,
        uuid: this.deviceSvc.uuid,
        version: this.deviceSvc.version
      }, { headers }).pipe(take(1)).toPromise() as any;
      step = 'initialize-sign-in';
      this._sessionStartedAt = new Date();
      await Promise.all([
        this.dataSvc.setPersistent(APP_SERVER_TIME_GAP_STORAGE_LABEL, this._sessionStartedAt.getTime() - new Date(createdAt).getTime()),
        this._refreshIdTokens()
      ]);
      step = 'set-info-with-account-manager';
      await this._setInfoWithAccountManager(pin);
      step = 'start-timer';
      await this._startSessionTimer();
      this._onSignIn$.emit();
      this._isInitialized = true;
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.signIn() at step ${step}`, error);
      if (step === 'do-request' && error.error) {
        return Promise.reject(error.error);
      }
      return Promise.reject(error);
    }
  }

  async signOut(): Promise<void> {
    let step = '';
    try {
      step = 'get-id-token-and-id-token-result';
      const [idToken, idTokenResult] = await Promise.all([
        this.afAuth.idToken.pipe(take(1)).toPromise(),
        this.afAuth.idTokenResult.pipe(take(1)).toPromise()
      ]);
      step = 'delete-session';
      const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
      await this.http.delete(`${ENVIRONMENT.appPlatform.apiUrl}sessions/${idTokenResult.claims.session_id}`, { headers }).pipe(take(1)).toPromise();
      step = 'refresh-id-token';
      this._idTokenResults = null;
      await this._refreshIdTokens();
      clearTimeout(this._endSessionTimeout);
      this._shouldRefreshSession = false;
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.signOut() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  async unroll(): Promise<void> {
    let step = '';
    try {
      step = 'get-device-id-and-id-tokens';
      const [deviceId, idToken, idTokenResult] = await Promise.all([
        this.dataSvc.getPersistent<string>(APP_DEVICE_ID_STORAGE_LABEL),
        this.afAuth.idToken.pipe(take(1)).toPromise(),
        this.afAuth.idTokenResult.pipe(take(1)).toPromise()
      ]);
      if (!deviceId && !idTokenResult?.claims?.device_id) {
        step = 'firebase-sign-out';
        await this.afAuth.signOut();
        return;
      }
      step = 'delete-device-on-server';
      try {
        const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
        await this.http.delete(`${ENVIRONMENT.appPlatform.apiUrl}devices/${idTokenResult?.claims?.device_id ?? deviceId}`, { headers }).pipe(take(1)).toPromise();
        step = 'refresh-id-token';
        await this._refreshIdTokens();
      } catch(err) {
        console.warn('Error deleting device on server', err);
      }
      step = 'firebase-sign-out-and-delete-local-storage-and-remove-account';
      await Promise.all([
        this.afAuth.signOut(),
        this.dataSvc.clearAll(),
        this._removeAccount(),
      ]);
    } catch (error) {
      console.error(`Error in AuthServiceFirebase.unroll() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  private async _onIdTokenChanged(): Promise<void> {
    let step = '';
    try {
      step = 'check-if-is-enrolled';
      const isEnrolled = await this.isEnrolled();
      if (!isEnrolled) {
        if (this._isEnrolled) {
          this._isEnrolled = false;
          this._onUnroll$.emit();
        }
        return;
      }
      this._isEnrolled = true;
      step = 'check-if-is-signed-in';
      const isSignedIn = await this.isSignedIn(false);
      if (isSignedIn && !this._isSignedIn && this._isInitialized) {
        this._onSignIn$.emit();
        return;
      }
      if (!this._isInitialized || (!isSignedIn && this._isSignedIn)) {
        this._onSignOut$.emit();
        return;
      }
    } catch (error) {
      console.error(`Error in AuthServiceFirebase._onIdTokenChanged() at step ${step}`, error);
      if (step === 'check-if-is-signed-in') {
        this._onSignOut$.emit();
      }
    }
  }

  private async _refreshIdTokens(refreshApp = true): Promise<void> {
    const promises = [];
    if (refreshApp) {
      const currentUser = await this.afAuth.currentUser;
      if (currentUser) {
        promises.push(currentUser.getIdToken(true));
        promises.push(currentUser.getIdTokenResult(true));
      } else {
        console.warn('No user when refreshing id token');
      }
    }
    await Promise.all(promises);
  }

  private async _refreshServerSession(): Promise<void> {
    let step = '';
    try {
      step = 'get-id-token-and-id-token-result';
      const [idToken, idTokenResult] = await Promise.all([
        this.afAuth.idToken.pipe(take(1)).toPromise(),
        this.afAuth.idTokenResult.pipe(take(1)).toPromise()
      ]);
      step = 'refresh-session';
      const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
      const { updatedAt } = await this.http.put(`${ENVIRONMENT.appPlatform.apiUrl}sessions/${idTokenResult.claims.session_id}`, {}, { headers }).pipe(take(1)).toPromise() as any;
      step = 'update-server-time-gap';
      await this.dataSvc.setPersistent(APP_SERVER_TIME_GAP_STORAGE_LABEL, new Date().getTime() - new Date(updatedAt).getTime());
      step = 'refresh-id-token';
      await this._refreshIdTokens();
      step = 'start-session-timer';
      await this._startSessionTimer();
    } catch (error) {
      console.error(`Error in AuthServiceFirebase._refreshServerSession() at step ${step}`, error);
      return Promise.reject(error);
    }
  }

  private _removeAccount(): Promise<void> {
    return this._isNative ? this.accountManagerSvc.removeAccount() : Promise.resolve();
  }

  private async _setInfoWithAccountManager(pin: string): Promise<void> {
    if (this._isNative) {
      const { userId } = await this.getSession().pipe(take(1)).toPromise();
      await this.accountManagerSvc.addAccount(userId, pin);
    }
  }

  private async _startSessionTimer(): Promise<void> {
    let step = '';
    try {
      step = 'get-id-token-result-and-server-time-gap';
      const [idTokenResult, serverTimeGap] = await Promise.all([
        this.afAuth.idTokenResult.pipe(take(1)).toPromise(),
        this.dataSvc.getPersistent<number>('serverTimeGap')
      ]);
      step = 'do-validations';
      if (!idTokenResult || !idTokenResult.claims.session_expires_at || !serverTimeGap) {
        return;
      }
      const endTime = idTokenResult.claims.session_expires_at - SESSION_TIME_MARGIN - new Date().getTime() + serverTimeGap;
      if (endTime <= 0) {
        if (this._isSignedIn) {
          this._onSignOut$.emit();
        }
        return;
      }
      step = 'setup-end-session-timeout';
      this._endSessionTimeout = setTimeout(() => {
        if (!this._shouldRefreshSession) {
          this._onSignOut$.emit();
          return;
        }
        return this._refreshServerSession().catch((error) => {
          console.error('Error refreshing server session', error);
          this._onSignOut$.emit();
        }).finally(() => this._shouldRefreshSession = false);
      }, endTime);
    } catch (error) {
      console.error(`Error in AuthServiceFirebase._startSessionTimer() at step ${step}`, error);
      return Promise.reject(error);
    }
  }
}
