import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreCollectionGroup, AngularFirestoreDocument } from '@angular/fire/firestore';

import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { changeTimestampsToDate } from '@app/utils/firestore/firestore.utils';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type CollectionGroupPredicate<T> = string| AngularFirestoreCollectionGroup<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  constructor(public afs: AngularFirestore) {
    this.afs.firestore.settings({});
  }

  add<T>(ref: CollectionPredicate<T>, data) {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }
  colGroup<T>(ref: CollectionGroupPredicate<T>,queryFn?): AngularFirestoreCollectionGroup<T> {
    return typeof ref === 'string' ? this.afs.collectionGroup<T>(ref, queryFn) : ref;
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map((actions) => {
      return actions.map((action) => {
        return { id: action.payload.doc.id, ...changeTimestampsToDate(action.payload.doc.data()) };
      });
    }));
  }
  colGroup$<T>(ref: CollectionGroupPredicate<T>, queryFn?): Observable<T[]> {
    return this.colGroup(ref, queryFn).snapshotChanges().pipe(map((actions) => {
      return actions.map((action) => {
        return { id: action.payload.doc.id, ...changeTimestampsToDate(action.payload.doc.data()) };
      });
    }));
  }

  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>): Promise<void> {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  doc$<T>(ref:  DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map((action) => {
      return { id: action.payload.id, ...changeTimestampsToDate(action.payload.data()) };
    }));
  }

  firstCol$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T> {
    return this.col$<T>(ref, queryFn).pipe(map((values) => values.length ? values[0] : null));
  }

  geoPoint(lat: number, lng: number): firebase.default.firestore.GeoPoint {
    return new firebase.default.firestore.GeoPoint(lat, lng);
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    this.col(ref).snapshotChanges().pipe(take(1)).subscribe();
  }

  inspectDoc(ref: DocPredicate<any>): void {
    this.doc(ref).snapshotChanges().pipe(take(1)).subscribe();
  }

  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  async upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const snap = await this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();
    return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
  }

  public get timestamp(): firebase.default.firestore.FieldValue { return firebase.default.firestore.FieldValue.serverTimestamp(); }
}
