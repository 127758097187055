import { Component } from '@angular/core';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';

import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { ENVIRONMENT } from '@app/config';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { PwaUpdateService } from '@services/pwa-update/pwa-update.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private analyticsSvc: AnalyticsService,
    private notificationsSvc: NotificationsService,
    private pwaUpdateSvc: PwaUpdateService,
    private platform: Platform,
    private router: Router,
    private toastSvc: ToastService,
    private translateSvc: TranslateService
  ) {
    this.initializeApp();
  }

  private _checkForPwaUpdates() {
    if(this.pwaUpdateSvc.isPwaAndSWUpdateEnabled) {
      this.pwaUpdateSvc.checkForUpdates()
      .subscribe(() => {
        this.toastSvc.showRaw('UPDATE_TOAST.MESSAGE', null, 'toast-success', 'UPDATE_TOAST.BUTTON', () => this.pwaUpdateSvc.activatePwaUpdate());
      }, (error) => {
        console.error('Error in AppComponent._checkForPwaUpdates(), error: ', error);
      });
    }
  }

  private async initializeApp() {
    this._checkForPwaUpdates();
    this.translateSvc.setDefaultLang(ENVIRONMENT.localeId);
    setTimeout(() => SplashScreen.hide(), 300);
    this._setUpAnalyticsRouterEventsLogic();
    if (this.platform.is('ios')) {
      await this.notificationsSvc.init(); // This is because push notifications must work in other to make phone authentication also work
    }
  }

  private _setUpAnalyticsRouterEventsLogic(): void {
    let routeValue: string, routeConfigPath: string, params: {[param: string]: string}, queryParams: {[param: string]: string};
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) { // this is to identify params and replace their values for their keys
        params = event.snapshot.params;
        queryParams = event.snapshot.queryParams;
        routeConfigPath = event.snapshot.routeConfig.path;
        routeValue = routeConfigPath;
        for (const key in params) {
          routeValue = routeValue.replace(`:${key}`, encodeURIComponent(params[key]));
        }
      }
      if (event instanceof NavigationEnd) {
        const url = routeValue && routeConfigPath ? event.urlAfterRedirects.replace(routeValue, routeConfigPath).split('?')[0] : event.urlAfterRedirects.split('?')[0];
        this.analyticsSvc.setCurrentScreen(url);
        this.analyticsSvc.logEvent('page_view', { ...params, ...queryParams, url });
      }
    });
  }
}
