<ion-content class="background-solid">
  <div class="main-container data-usage-container">
    <div class="icon-container fade-in-transition duration-05">
      <ion-icon name="shape-oval" class="icon main" color="tertiary"></ion-icon>
      <ion-icon class="icon in-blob" color="primary" name="question-bubble"></ion-icon>
    </div>
    <h1 class="text-main-strong fade-in-transition delay-1 duration-05">Términos y condiciones de uso App Abastible</h1>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Introducción</h2>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>Los siguientes Términos y Condiciones de Uso de la App de Abastible, en adelante los “Términos y Condiciones de Uso”, serán aplicables a aquellas personas que deseen instalar o instalen la App de Abastible en sus dispositivos móviles, en adelante indistintamente “usuario”. Si usted no está de acuerdo con estos Términos y Condiciones de Uso, deberá abstenerse de instalar la App de Abastible.</li>
      <li>Abastible es el proveedor de la App de Abastible que es una aplicación que permite al usuario pedir su gas en cilindros o estanque desde su dispositivo móvil, de forma más rápida y fácil, desde cualquier ubicación dentro del territorio nacional.</li>
      <li>Abastible se reserva el derecho de revisar y modificar periódicamente los presentes Términos y Condiciones de Uso, produciendo dichas modificaciones efectos legales a contar de la fecha de publicación de la modificación. Abastible informará a sus usuarios registrados tan pronto sea posible dichas modificaciones mediante los canales que estime convenientes, garantizando su publicidad. El uso de la App de Abastible con posterioridad a las modificaciones efectuadas, implicará su aceptación por parte de los usuarios.</li>
      <li>Cualquier controversia entre Abastible y sus usuarios respecto de la App de Abastible y de las operaciones que se hagan en ella, se regirá por la legislación chilena y será resuelta por los Tribunales Ordinarios de Santiago, a cuya jurisdicción los usuarios se someten.</li>
    </ul>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Requisitos para el uso de la App de Abastible</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      La App de Abastible está disponible para personas mayores de 18 años y para ser utilizada sólo en Chile. El acceso y utilización de la App de Abastible requiere que el usuario disponga de acceso a Internet, ya sea por un plan, bolsa de datos, mediante conexión Wi Fi u otro medio disponible, requiere dispositivos compatibles y determinados software (podrían aplicarse tarifas); se podrían requerir actualizaciones periódicas. Los servicios de la App podrían verse afectados por el rendimiento de estos factores.    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Entrega de datos personales del usuario o cliente y uso de claves y registro</h2>
    <h3 class_="text-main">Cookies</h3>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>Abastible dispone de cookies con el fin de entregar mejores condiciones de servicio, y para recoger información para propósitos estadísticos.</li>
      <li>Las cookies consisten en una tecnología implementada en el browser (software o programa para el acceso a un servicio web cualquiera) que permite que el servidor recuerde información sobre los usuarios para facilitar la navegación o interacción por un sitio Web o portal en Internet. La App de Abastible usa esta tecnología cookie. Se hace presente que esta tecnología no es utilizada por Abastible para la lectura de datos del disco duro de los usuarios, ni de los archivos cookie creados por otros proveedores de servicios de Internet.</li>
      <li>Es responsabilidad del usuario consultar las instrucciones de su sistema navegador, especialmente en lo relativo a configuraciones, donde se permite escoger la funcionalidad que comunica la existencia de cookies y/o impide la instalación de cookies, si es el deseo del usuario o cliente.</li>
      <li>Sin el acceso de cookies el usuario no puede tener acceso a determinadas prestaciones con las que cuenta la App de Abastible. En consecuencia, si el usuario no tiene habilitadas las cookies, puede no tener acceso a ciertas herramientas de la App de Abastible o puede ver afectado un correcto funcionamiento.</li>
    </ul>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Veracidad de la información</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      Toda la información que facilite el usuario a Abastible por cualquier medio deberá ser exacta, verdadera, completa y actualizada.
    </p>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>A estos efectos, el usuario garantiza la autenticidad de todos aquellos datos que comunique a Abastible como consecuencia del llenado de los formularios necesarios para su registro en la App de Abastible, la suscripción de los servicios sobre Internet y aquella que provea a Abastible por cualquier otra forma. De igual forma, será responsabilidad del usuario mantener toda la información entregada a Abastible permanentemente actualizada de forma que responda, en cada momento, a su situación real.</li>
      <li>Abastible podrá suspender temporalmente los servicios de la App de Abastible, cuando tenga dudas sobre la veracidad, exactitud o actualización de la información proporcionada por un usuario, mientras la información suministrada no haya sido confirmada por el mismo. El usuario será el único responsable de las manifestaciones falsas o inexactas que realice, y de los perjuicios que pudiere causar a Abastible o a terceros por la información inexacta que proporcione.</li>
    </ul>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Uso de los datos personales</h2>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>Abastible se preocupa por la protección de datos de carácter personal de sus usuarios y clientes, por lo cual, asegura que su tratamiento se efectuará con estricta sujeción a la legislación vigente.</li>
      <li>La obtención de datos personales en la App de Abastible se realiza de manera voluntaria, con la finalidad de acceder a los beneficios y servicios que ofrece Abastible.</li>
      <li>Para acceder a la App de Abastible, el usuario deberá registrarse previamente ingresando los datos personales que le sean solicitados o a través de Facebook o Google con los términos y condiciones de uso propios de cada plataforma.</li>
      <li>Los pagos efectuados a través de Webpay One Click en la APP de Abastible, se regirán por los términos y condiciones de uso de transbank.</li>
      <li>El usuario autoriza conforme lo indicado en el artículo 4 de la Ley 19.628 el tratamiento de sus datos personales por ABASTIBLE y sus empresas relacionadas, filiales o matrices con finalidades de marketing, comunicar ofertas y promociones y con el objeto de entregar información y/o beneficios a los usuarios y clientes. Los referidos datos personales podrán en casos concretos ser comunicados a terceros, para cumplir con las finalidades recién mencionadas.</li>
      <li>El usuario puede solicitar la información, rectificación, eliminación y/o cancelación de sus datos cuando lo estime conveniente, en conformidad a la Ley N° 19.628.</li>
      <li>De vez en cuando se podrá solicitar información a personas que nos hayan enviado los datos de contacto a través de encuestas. La participación en éstas es totalmente voluntaria, por lo que será tu decisión revelar o no esta información. La encuesta se utilizará exclusivamente con propósitos de seguimiento o para mejorar el rendimiento de la App de Abastible. En caso de comunicarse los resultados de dicha encuesta, se omitirá cualquier señal que permita identificar a las personas consultadas.</li>
      <li>En el uso de la App de Abastible, la información relativa a su cuenta de Abastible, incluyendo tarifas e información de facturación, se almacenan en el caché su equipo móvil. Usaremos sus datos de localización para identificar su ubicación con respecto a los distribuidores o camiones repartidores más cercanos. Vamos a utilizar su conexión de Internet para autenticar las credenciales de usuario y podemos almacenar dichas credenciales en los sistemas de Abastible para la configuración del servicio y con fines de prestación de servicios.</li>
      <li>La App de Abastible está destinada para uso personal de cada usuario, por lo tanto, su uso será de su exclusiva responsabilidad. En consecuencia, el extravío, robo, hurto, mal uso o cualquiera otra circunstancia que afecte el uso de la App de Abastible será de su responsabilidad. Asimismo, cualquier instrucción o declaración que se ejecute a través de la App de Abastible se entenderá que ha emanado válida, legítima y auténticamente de un acto personal ejecutado por el usuario. En caso que exista mal uso de la App, el usuario deberá dar aviso e informar a Abastible apenas tenga conocimiento de la situación.</li>
    </ul>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Propiedad intelectual y propiedad industrial</h2>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>Todo contenido u obra que el usuario vea, lea o a la cual acceda en la App de Abastible (imágenes, fotografías, ilustraciones, iconos, textos, código fuente, diseño o cualquier otro elemento de contenido), se encuentra protegido bajo la legislación nacional y los tratados internacionales sobre propiedad intelectual e industrial, salvo que expresamente se señale lo contrario. El usuario no podrá vender, reproducir, distribuir, modificar, publicar o cualquier otra actividad semejante, con cualquier elemento de contenido de la App de Abastible, ni tampoco usarlo en ningún otro portal en Internet, para propósito alguno.</li>
      <li>Abastible no concede ninguna licencia, derecho o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual, o sobre cualquier otra propiedad o derecho relacionado con la App de Abastible o con los servicios que ofrece, salvo la licencia de que trata la sección siguiente. Abastible podrá terminar inmediatamente el derecho del usuario para acceder a la App de Abastible, en caso de infracción del usuario a estas normas. El usuario será responsable de todo daño o perjuicio causado a Abastible y/o a terceros, que deriven de las infracciones legales en que incurra.</li>
      <li>Se deja expresa constancia que todo elemento de contenido, tal como artículos, publicaciones, noticias e información publicada, exhibida, transmitida o comunicada en la App de Abastible, será de responsabilidad de su autor o de la persona que las haya proveído en conformidad a las leyes sobre propiedad intelectual e industrial.</li>
      <li>Abastible es titular de los derechos de propiedad industrial relativos a sus productos y servicios, así como los relativos a marcas registradas y/o nombres de dominio «Abastible.cl».</li>
      <li>La App de Abastible puede contener vínculos, links y/o referencias a sitios de terceros fabricantes, productores y/o proveedores de bienes y/o servicios. Abastible reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, y su sola mención o aparición en la App de Abastible no implica la existencia de derechos o responsabilidad alguna de Abastible sobre los mismos. Abastible no recomienda ni avala ningún producto o servicio ofrecido en dichos sitios, asimismo, Abastible no es responsable de las prácticas de seguridad o privacidad, o el contenido y disponibilidad de los mismos.</li>
    </ul>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Licencia</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      La App de Abastible se licencia y no se vende. La licencia para usar la App de Abastible, está sujeta a la aceptación previa de estos Términos y Condiciones de Uso por usted. Esta licencia es concedida en forma gratuita para el uso de la App de Abastible y se limita a una licencia no transferible para usar la App de Abastible en dispositivos compatibles que usted posea o controle. Esta licencia no permite usar la App de Abastible en cualquier dispositivo que no sea de su propiedad o control. Usted no puede distribuir ni poner la App de Abastible disponible en una red donde pueda ser utilizada por múltiples dispositivos al mismo tiempo. Usted no puede alquilar, arrendar, ceder, prestar, vender, transferir redistribuir o sublicenciar la App de Abastible. Usted no puede copiar (salvo lo expresamente permitido por esta licencia), descompilar, realizar ingeniería inversa, desmontar, intentar descifrar el código fuente, modificar o crear trabajos derivados de la aplicación licenciada, las actualizaciones, o cualquier parte de la misma (excepto y únicamente en la medida en que cualquier restricción anterior esté prohibida por la ley aplicable). Cualquier intento de llevar a cabo las conductas anteriores, se entenderá como una violación de los derechos de Abastible.
    </p>
    <p class="text fade-in-transition delay-2 duration-05">
      Los términos de esta licencia se aplican a cualquier actualización proporcionada por Abastible, a menos que dicha actualización esté acompañada por una licencia independiente, en cuyo caso los términos de dicha licencia prevalecerán. La licencia estará vigente hasta que se le de término por usted o por Abastible. Los derechos otorgados por esta licencia terminarán automáticamente sin previo aviso por parte de Abastible, en caso que usted no de cumplimiento a cualquier término (s) de la presente Licencia. A la terminación de la Licencia, usted deberá cesar el uso de la aplicación licenciada y destruir todas las copias, totales o parciales, de la misma.
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Responsabilidad</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      Abastible no garantiza ni declara que el uso de los servicios de la App de Abastible será sin interrupciones o libre de errores. Cada cierto tiempo Abastible podrá retirar los servicios de la App de Abastible por períodos indefinidos de tiempo, o cancelar tales servicios en cualquier momento, sin necesidad de previo aviso. Los servicios de la App de Abastible y la App de Abastible son proporcionados «tal cual» sin soporte ni mantenimiento y disponible para su uso, sin garantías de ningún tipo, ya sean expresas o implícitas, incluyendo todas las garantías implícitas de comercialización, idoneidad para un propósito particular, derechos y no infracción. En ningún caso Abastible, sus directores, empleados, afiliados, agentes, contratistas, subdistribuidores, gerentes o cualquier otro vinculado directa o indirectamente a Abastible, serán responsables por cualquier daño derivado del uso de la App de Abastible. Abastible realizará esfuerzos razonables para proteger la información entregada por usted en relación con la App de Abastible. Abastible no declara ni garantiza que la App de Abastible será libre de pérdidas, corrupción, ataques, virus, interferencias, intromisiones en la seguridad por hacking o de otro tipo.
    </p>
    <p class="text fade-in-transition delay-2 duration-05">
      Abastible no garantiza ni asegura la entrega de cilindros o de carga de gas (“Pedido Cilindros” o “Pedido Estanque”) en cuanto a su puntualidad, calidad, idoneidad, disponibilidad de la entrega o que la entrega no será interrumpida y no estará libre de error. Abastible no será responsable de los puntos mencionados con anterioridad.
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Varios</h2>
    <ul class="text fade-in-transition delay-2 duration-05">
      <li>El usuario se obliga a utilizar la App de Abastible de conformidad con la legislación vigente y estos Términos y Condiciones de Uso, debiendo darle un uso consistente con las exigencias que imponen el orden público, la moral, las buenas costumbres generalmente aceptadas y principalmente el respeto de terceros.</li>
      <li>A tal efecto, el usuario se compromete a no utilizar la App de Abastible de forma perjudicial de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de la App de Abastible, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático de Abastible, de otros usuarios o de cualquier usuario de Internet.</li>
      <li>Entre otras obligaciones, de manera ejemplar y sin que implique limitación alguna, al usuario se le prohíbe:
        <ul>
          <li>(a) reproducir, copiar, distribuir o permitir el acceso a la App de Abastible a través de cualquier forma de comunicación pública, transformar o modificar los servicios, salvo que se cuente con la autorización del titular de los correspondientes derechos de propiedad intelectual o industrial, o ello resulte legalmente permitido;</li>
          <li>(b) suprimir, eludir o manipular el derecho de propiedad intelectual e industrial y demás datos identificadores de los derechos de Abastible o de otros titulares, incorporados a los servicios, así como los dispositivos técnicos de protección, las huellas digitales, medidas de autenticación biométrica o cualesquiera mecanismos de información que pudieren contener y/o solicitar los servicios;</li>
          <li>(c) ejecutar, inducir o promover actos delictivos, denigrantes, difamatorios, o que en general sean contrarios a la ley, la moral, al orden público, o la seguridad nacional o que fueren discriminatorios por razón de sexo, raza, religión, creencias, edad o condición;</li>
          <li>(d) no respetar la privacidad de los usuarios;</li>
          <li>(e) usar los servicios con propósitos comerciales, incluyendo la promoción de cualquier bien o servicio;</li>
          <li>(f) obstaculizar, entorpecer, restringir o limitar de cualquier modo el uso de los servicios por parte de otros usuarios; y</li>
          <li>(g) proporcionar información que no sea exacta, verdadera, completa y actualizada o que de cualquier manera menoscabe a Abastible o a terceros.</li>
        </ul>
      </li>
      <li>Los servicios, productos y precios publicitados a través de la App de Abastible, están sujetos a disponibilidad de stock y términos y condiciones comerciales establecidas y publicadas por Abastible, para cada servicio y producto.</li>
      <li>La App de Abastible puede contener enlaces o links a sitios mantenidos por terceros y eventualmente pueden proporcionar material de terceros desde los sitios de éstos. Abastible no es responsable por la funcionalidad, eficacia de los servicios y por los contenidos de aquellos sitios.</li>
      <li>Abastible no es responsable por los reclamos, pérdidas, acciones, daños, demandas judiciales o procedimientos que surjan o estén relacionados de alguna manera con ocasión de los enlaces con sitios de terceros. La presente exención de responsabilidad se extiende a los enlaces con sitios mantenidos por terceros, donde se realiza cualquier tipo de transacción entre y/o mediante el usuario y el tercero responsable del sitio.</li>

    </ul>


    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Security</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Links to Other Sites</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Children’s Privacy</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Changes to This Privacy Policy</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.<br>
      This policy is effective as of 2021-02-02
    </p>
    <h2 class="text-main-strong fade-in-transition delay-2 duration-05">Contact Us</h2>
    <p class="text fade-in-transition delay-2 duration-05">
      If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contacto@numi.cl.
    </p>
  </div>
</ion-content>