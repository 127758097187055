import { Injectable } from '@angular/core';

import { APP_VERSION } from '@app/constants';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  getVersionNumber(): string {
    return APP_VERSION;
  }
}
