import { ENVIRONMENT } from '@app/config';
import { ERROR_RATE_PERCENTAGE } from '@app/constants';

export function triggerRandomError(rejectError: any): boolean {
  if (ENVIRONMENT.name === 'offline') {
    const random = Math.round(Math.random() * 100);
    if (random <= ERROR_RATE_PERCENTAGE) {
      const error = { code: 'unknown-error', message: 'Unknown error' };
      if (typeof rejectError === 'object') {
        rejectError.error(error);
      } else {
        rejectError(error);
      }
      return true;
    }
  }
  return false;
}
