import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DefaultCurrencyPipe } from '@pipes/default-currency.pipe';

@NgModule({
  declarations: [
    DefaultCurrencyPipe
  ], exports: [
    DefaultCurrencyPipe
  ],
  providers: [
    DecimalPipe,
    DefaultCurrencyPipe
  ]
})
export class PipesModule {}
