import { EnvironmentConfig } from '@app/interfaces/environment-config.interface';

export const ENVIRONMENT: EnvironmentConfig = {
  name: 'prod',
  production: true,
  useMock: false,
  app: {
    packageName: 'cl.abastible.app',
    bundleId: 'cl.abastible.app',
    group: 'QU74JYJD55',
    name: 'Billetera Abastible',
    authorEmail: '',
    authorWebsite: '',
    accountManagerKey: '?E(G+KbPeShVmYq3',
    appStoreUrl: '', // TODO: add App Store url
    playStoreUrl: '' // TODO: add Play Store url
  },
  appPlatform: {
    apiUrl: 'https://api.abastible-personas.heypay.cl/',
    firebaseConfig: {
      apiKey: 'AIzaSyBEOBJaVhemsvJVwUaOTUNpSXdx6cerFAQ',
      authDomain: 'abastible-app-prod.firebaseapp.com',
      projectId: 'abastible-app-prod',
      databaseURL: 'https://abastible-app-dev.firebaseio.com',
      storageBucket: 'abastible-app-prod.appspot.com',
      messagingSenderId: '635525412531',
      appId: '1:635525412531:web:38e694eeac92e0e8d7e2c6',
      measurementId: 'G-YVTF6LJ51W',
      vapidKey: 'BEKYL7hUyTeHHnL8CGgjJ2BQYyt636vNdmE2UKRqLFTOiZtFjurh13UtEVsNUWAd6O3-OTGPsLzlp1sgWtzxelc'
    }
  },
  accountsEnginePlatform: {
    apiUrl: 'https://accounts-engine.heypay.cl/',
    apiUrlShort: 'https://abastible.sp1.cl/'
  },
  termsAndConditionsUrl: 'https://www.abastible.cl/gas-cilindro/terminos-y-condiciones-de-uso-app/',
  koathUrl: 'https://koath-chek.heypay.cl/',
  localeId: 'es-CL',
  whatsappNumber: '56225831156'
};
