/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';

import { ENVIRONMENT } from '@app/config';
import { AccountManagerServiceMock } from '@services/account-manager/account-manager.service.mock';

const KEY = ENVIRONMENT.app.accountManagerKey;
const ACCOUNT_TYPE = ENVIRONMENT.app.packageName;
const GROUP_ID = `${ENVIRONMENT.app.group}.${ENVIRONMENT.app.bundleId}`;
const SCOPE = 'token';

export interface AccountManagerServiceInterface {
  addAccount(username: string, password: string): Promise<void>;
  getDataFromKey(key: string): Promise<string>;
  getPassword(): Promise<string>;
  getUserAccount(): Promise<any>;
  removeAccount(): Promise<void>;
  setUserData(userData: any): Promise<void>;
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.name === 'offline' ? AccountManagerServiceMock : AccountManagerService
})
export class AccountManagerService implements AccountManagerServiceInterface {

  private _accountManager: {
    addAccount: (accountName: string, token: string, accountType: string, group: string, userData: { [key: string]: boolean | number | string }, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    getDataFromKey: (accountType: string, group: string, key: string, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    getPassword: (accountType: string, group: string, scope: string, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    getUserAccount: (accountType: string, group: string, scope: string, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    removeAccount: (accountType: string, scope: string, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    setPassword: (accountType: string, group: string, scope: string, newPassword: string, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void,
    setUserData: (accountType: string, group: string, data: { [key: string]: boolean | number | string }, successCallback: (result: any) => void, errorCallback: (error: any) => void) => void
  };

  constructor() {
    this._init();
  }

  public addAccount(username: string, password: string): Promise<void> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    const userData = { scope: SCOPE, uid: username, univ_num: username };
    return new Promise<void>(async (resolve, reject) => {
      this._accountManager.addAccount(username, password, ACCOUNT_TYPE, GROUP_ID, userData,
        () => resolve(),
        (error) => this._handleError(reject, 'could not add account', error)
      );
    });
  }

  public getDataFromKey(key: string): Promise<string> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    return new Promise<string>(async (resolve, reject) => {
      this._accountManager.getDataFromKey(ACCOUNT_TYPE, GROUP_ID, key,
        (result) => resolve(result[key]),
        (error) => this._handleError(reject, `could not get data from key ${key}`, error)
      );
    });
  }

  public getPassword(): Promise<string> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    return new Promise<string>(async (resolve, reject) => {
      this._accountManager.getPassword(ACCOUNT_TYPE, GROUP_ID, SCOPE,
        (response) => resolve(response[SCOPE]),
        (error) => this._handleError(reject, 'could not get password', error)
      );
    });
  }

  public getUserAccount(): Promise<any> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    return new Promise<string>(async (resolve, reject) => {
      this._accountManager.getUserAccount(ACCOUNT_TYPE, GROUP_ID, SCOPE,
        (result) => resolve(result),
        (error) => this._handleError(reject, 'could not get user account', error, null)
      );
    });
  }

   public removeAccount(): Promise<void> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    return new Promise<void>(async (resolve, reject) => {
      this._accountManager.removeAccount(ACCOUNT_TYPE, SCOPE,
        () => resolve(),
        (error) => this._handleError(reject, 'could not remove account', error)
      );
    });
  }

  public setUserData(userData: any): Promise<void> {
    if (!this._accountManager) {
      throw new Error('Account manager not available.');
    }
    userData = { ...userData, scope: SCOPE };
    return new Promise<void>(async (resolve, reject) => {
      this._accountManager.setUserData(ACCOUNT_TYPE, GROUP_ID, userData,
        () => resolve(),
        (error) => this._handleError(reject, 'could not set user data', error)
      );
    });
  }

  private _handleError(reject: any, errorMsg: string, error: any, type = 'error'): void {
    if (type === 'error') {
      console.error(errorMsg, error);
    } else if (type === 'warn') {
      console.warn(errorMsg, error);
    } else {
      console.error(errorMsg, error);
    }
    reject(error);
  }

  private _init(): void {
    if ('its' in window && 'accountManager' in window['its']) {
      const { accountManager } = window['its'] as any;
      accountManager.initWithKey(KEY);
      this._accountManager = accountManager;
    }
  }
}
