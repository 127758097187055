import { CouponTransfer } from '@app/api/resources/coupon-transfers/coupon-transfers.service';
import { COUPON_LOST, COUPON_SENT_BY_LINK, COUPON_TRANSFERRED } from '@app/api/sources/mock/coupons/coupons.mock';
import { IDENTITY_VALIDATED_WITH_COUPONS_USER_1 } from '@sources/mock/user/users.mock';

export const COUPON_TRANSFER_ASSIGNED: CouponTransfer = {
  amount: COUPON_TRANSFERRED.amount,
  assignedAt: COUPON_TRANSFERRED.lastTransferAt,
  canceledAt: null,
  couponId: COUPON_TRANSFERRED.id,
  createdAt: COUPON_TRANSFERRED.lastTransferAt,
  destinationUserId: null,
  id: COUPON_TRANSFERRED.lastTransferId,
  nationalId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.nationalId,
  originUserId: COUPON_TRANSFERRED.previousUserId,
  phoneNumber: null,
  status: 'assigned',
  updatedAt: COUPON_TRANSFERRED.lastTransferAt,
};

export const COUPON_TRANSFER_SENT: CouponTransfer = {
  amount: COUPON_SENT_BY_LINK.amount,
  assignedAt: null,
  canceledAt: null,
  couponId: COUPON_SENT_BY_LINK.id,
  createdAt: COUPON_SENT_BY_LINK.lastTransferAt,
  destinationUserId: null,
  id: COUPON_SENT_BY_LINK.lastTransferId,
  nationalId: null,
  originUserId: null,
  phoneNumber: null,
  status: 'pending',
  updatedAt: COUPON_TRANSFERRED.lastTransferAt,
};
export const COUPON_TRANSFER_LOST: CouponTransfer = {
  amount: COUPON_LOST.amount,
  assignedAt: null,
  canceledAt: null,
  couponId: COUPON_LOST.id,
  createdAt: COUPON_LOST.lastTransferAt,
  destinationUserId: null,
  id: COUPON_LOST.lastTransferId,
  nationalId: null,
  originUserId: null,
  phoneNumber: null,
  status: 'assigned',
  updatedAt: COUPON_LOST.lastTransferAt,
};

export const COUPONS_TRANSFER_MOCKS: CouponTransfer[] = [
  COUPON_TRANSFER_ASSIGNED,
  COUPON_TRANSFER_SENT,
];
