import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/es-CL';
import { isDevMode, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/remote-config';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import player from 'lottie-web';
import { Ng10RutModule } from 'ng10-rut';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ENVIRONMENT } from '@app/config';
import { PrivacyPolicyPage } from '@app/pages/privacy-policy/privacy-policy.page';
import { PipesModule } from '@pipes/pipes.module';


registerLocaleData(locale);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [ AppComponent, PrivacyPolicyPage ],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireRemoteConfigModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(ENVIRONMENT.appPlatform.firebaseConfig),
    LottieModule.forRoot({ player: playerFactory }),
    ClipboardModule,
    IonicModule.forRoot(),
    Ng10RutModule,
    PipesModule,
    AppRoutingModule,
    ZXingScannerModule,
    ServiceWorkerModule.register('/combined-worker.js', {
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whatever comes first).
      enabled: ENVIRONMENT.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    FingerprintAIO,
    { provide: CONFIG, useValue: { send_page_view: false } },
    // { provide: DEBUG_MODE, useValue: true }, // uncomment for debugging analytics
    { provide: DEFAULTS, useValue: { enableAwesome: true } },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: SETTINGS, useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
