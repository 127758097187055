import { CouponRedeem } from '@resources/coupon-redeems/coupon-redeems.service';
import * as moment from 'moment';

export const COUPON_REDEEM_WITH_AMOUNT_45: CouponRedeem = {
  additionalData: null,
  amount: 45,
  createdAt: moment().subtract(2, 'd').toDate(),
  chargeInfo: {
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6'
  },
  id: 'LfCnmpimBNNyJH7WjSn',
  chargeUrl: 'https://www.abastible.cl/charges/4',
  couponId: 'oiWAgkmK6AmNOMgABTuL',
  depositId: null,
  redeemedAt: moment().subtract(2, 'd').toDate(),
  status: 'done',
  statusData: null,
  updatedAt: moment().subtract(2, 'd').toDate()

};

export const COUPON_REDEEMS_MOCKS: CouponRedeem[] = [
  COUPON_REDEEM_WITH_AMOUNT_45
];