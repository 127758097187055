import * as moment from 'moment';

import { Profile } from '@resources/profile/profile.sevice';
import {
  IDENTITY_VALIDATED_WITH_COUPONS_USER_1,
  IDENTITY_VALIDATED_WITH_COUPONS_USER_2,
  IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1
} from '@sources/mock/user/users.mock';

export const PROFILES_MOCKS: Profile[] = [
  {
    createdAt: moment().subtract(1, 'M').toDate(),
    email: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.email,
    firstName: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.firstName,
    id: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.profileId,
    lastName: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.lastName,
    updatedAt: moment().subtract(3, 'd').toDate(),
    userId: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.id
  },
  {
    createdAt: moment().subtract(15, 'd').toDate(),
    email: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.email,
    firstName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.firstName,
    id: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.profileId,
    lastName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.lastName,
    updatedAt: moment().subtract(7, 'd').toDate(),
    userId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id
  },
  {
    createdAt: moment().subtract(3, 'd').toDate(),
    email: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.email,
    firstName: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.firstName,
    id: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.profileId,
    lastName: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.lastName,
    updatedAt: moment().subtract(3, 'd').toDate(),
    userId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.id
  }
];
