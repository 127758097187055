import { EventEmitter, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthServiceMock } from '@app/api/sources/mock/auth/auth.service';
import { ENVIRONMENT } from '@app/config';
import { AuthServiceFirebase } from '@sources/firebase/auth/auth.service';

// this interface is used to register fcm token
export interface SessionBase {
  deviceId: string;
  userId: string;
}

export interface Session extends SessionBase {
  sessionId: string;
  startedAt: Date;
  token: string;
}

export interface AuthServiceInterface {
  enroll(phoneNumber: string, code: string, wasResend: boolean): Promise<void>;
  getSession(): Observable<Session>;
  hasConnection(): boolean;
  isEnrolled(): Promise<boolean>;
  isSignedIn(): Promise<boolean>;
  listenToOtp(): Observable<{
    code: 'user-automatic-sign-in' | 'verification-code-received';
    verificationCode?: string;
    verificationId?: string;
  }>;
  onSignIn(): EventEmitter<void>;
  onSignOut(): EventEmitter<void>;
  onUnroll(): EventEmitter<void>;
  registerDevice(phoneNumber: string, pin: string): Observable<string>;
  resendOtp(phoneNumber: string): Promise<void>;
  sendOtp(phoneNumber: string): Observable<{
    status: string;
    verificationId?: string;
    code?: string;
  }>;
  setRecaptchaVerifierContainer(): void;
  signIn(pin: string): Promise<void>;
  signOut(): Promise<void>;
  unroll(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.useMock ? AuthServiceMock : AuthServiceFirebase
})
export class AuthService implements AuthServiceInterface {
  enroll(phoneNumber: string, code: string, wasResend: boolean): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getSession(): Observable<Session> {
    throw new Error('Method not implemented.');
  }
  hasConnection(): boolean {
    throw new Error('Method not implemented.');
  }
  isEnrolled(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  isPinConfigured(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  isSignedIn(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  listenToOtp(): Observable<{
    code: 'user-automatic-sign-in' | 'verification-code-received';
    verificationCode?: string;
    verificationId?: string;
  }> {
    throw new Error('Method not implemented.');
  }
  onSignIn(): EventEmitter<void> {
    throw new Error('Method not implemented.');
  }
  onSignOut(): EventEmitter<void> {
    throw new Error('Method not implemented.');
  }
  onUnroll(): EventEmitter<void> {
    throw new Error('Method not implemented.');
  }
  registerDevice(phoneNumber: string, pin: string): Observable<string> {
    throw new Error('Method not implemented.');
  }
  resendOtp(phoneNumber: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  sendOtp(phoneNumber: string): Observable<{
    status: string;
    verificationId?: string;
    code?: string;
  }> {
    throw new Error('Method not implemented.');
  }
  setRecaptchaVerifierContainer(): void {
    throw new Error('Method not implemented.');
  }
  signIn(pin: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  signOut(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  unroll(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
