import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENVIRONMENT } from '@app/config';
import { FcmService } from '../fcm/fcm.service';
import { NotificationsServiceMock } from './notifications.service.mock';

export interface Notification {
  message: string;
}
export interface NotificationsServiceInterface {
  init(): Promise<void>;
  listenToNotifications(): Observable<any>;
  removeToken(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.name === 'offline' ? NotificationsServiceMock : FcmService,
})
export class NotificationsService implements NotificationsServiceInterface {
  init(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  listenToNotifications(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  removeToken(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
