import { EventEmitter, Injectable } from '@angular/core';

import * as faker from 'faker';
import * as moment from 'moment';
import { Observable, Subscriber } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { triggerRandomError } from '@app/api/utils/mock/mock.util';
import { AuthService, Session } from '@resources/auth/auth.service';
import { User } from '@resources/user/user.service';
import { APP_SESSION_END_TIME_STORAGE_LABEL, APP_USER_STORAGE_LABEL, DataService, MOCK_LOGIN_PIN_STORAGE_LABEL } from '@services/data/data.service';
import { NetworkService } from '@services/network/network.service';
import { MockService } from '@sources/mock/mock.service';
import { USERS_MOCKS } from '@sources/mock/user/users.mock';

const SESSION_MINUTES = 3;
const VERIFICATION_CODE = '123456';

// The value will be set on enrollment or from local storage
export let USER_ID;

@Injectable()
export class AuthServiceMock implements AuthService {
  private _endSessionTimeout: NodeJS.Timeout;
  private _loginFailedAttempts = 0;
  private _onSignIn$ = new EventEmitter() as EventEmitter<void>;
  private _onSignOut$: EventEmitter<void>;
  private _onUnroll$ = new EventEmitter() as EventEmitter<void>;
  private _sessionStartedAt: Date;
  private _shouldRefreshSession = false;
  private _user: Observable<User>;
  private _userSubscriber: Subscriber<User>;
  constructor(
    private dataSvc: DataService,
    private mockSvc: MockService,
    private networkSvc: NetworkService
  ) {
    this._onSignOut$ = new EventEmitter();
    this._user = new Observable((observer) => {
      this._userSubscriber = observer;
      this.dataSvc.getPersistent<User>(APP_USER_STORAGE_LABEL).then((user) => {
        USER_ID = user?.id || null;
        observer.next(user);
      });
    });
    this.isSignedIn().then((isSignedIn) => {
      if (!isSignedIn) {
        setTimeout(() => this._onSignOut$.emit(), 500);
        return;
      }
    });
    this._startTimer();
  }

  private async _startSession(): Promise<void> {
    this._sessionStartedAt = new Date();
    await this.dataSvc.setPersistent(APP_SESSION_END_TIME_STORAGE_LABEL, this._sessionStartedAt.getTime() + SESSION_MINUTES * 60000);
    this._startTimer();
  }

  private _startTimer(): Promise<void> {
    return this.dataSvc.getPersistent<number>(APP_SESSION_END_TIME_STORAGE_LABEL).then((endTime) => {
      const now = new Date().getTime();
      if (endTime < now) {
        return;
      }
      this._endSessionTimeout = setTimeout(() => {
        if (this._shouldRefreshSession) {
          this._startSession();
          this._shouldRefreshSession = false;
          return;
        }
        this._onSignOut$.emit();
      }, endTime - now);
    });
  }

  enroll(phoneNumber: string, code: string, wasResend: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        let returnCode, returnMessage;
        switch (code) {
          case '100000':
            returnCode = 'auth/code-expired';
            returnMessage = 'The SMS code has expired. Please re-send the verification code to try again.';
            break;
          case '200000':
            returnCode = 'auth/invalid-verification-code';
            returnMessage = 'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.';
            break;
          case '300000':
            returnCode = 'wrong-verification-code';
            break;
          case '400000':
            returnCode = 'verification-not-available';
            break;
          case '500000':
            returnCode = 'max-check-attempts-reached';
            break;
          default:
            if (code !== VERIFICATION_CODE) {
              returnCode = 'auth/invalid-verification-code';
              returnMessage = 'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.';
            }
            break;
        }
        if (returnCode) {
          reject({ code: returnCode, message: returnMessage });
          return;
        }
        resolve();
      }, 1500);
    });
  }

  getSession(): Observable<Session> {
    return this._user.pipe(map((user) => {
      return user ? {
        accountsEngineToken: 'accounts-engine-token-mock',
        deviceId: 'device-id-mock',
        sessionId: 'session-id-mock',
        token: 'token-mock',
        userId: 'user-id-mock',
        startedAt: this._sessionStartedAt
      } : null;
    }));
  }

  hasConnection(): boolean {
    return this.networkSvc.hasConnection();
  }

  async isEnrolled(): Promise<boolean> {
    const [session, hasPinConfigured] = await Promise.all([
      this.getSession().pipe(take(1)).toPromise(),
      this.isPinConfigured()
    ]);
    return !!session && hasPinConfigured;
  }

  async isPinConfigured(): Promise<boolean> {
    const pin = await this.dataSvc.getPersistent(MOCK_LOGIN_PIN_STORAGE_LABEL);
    return !!pin;
  }

  async isSignedIn(): Promise<boolean> {
    const endTime = await this.dataSvc.getPersistent(APP_SESSION_END_TIME_STORAGE_LABEL);
    return endTime && endTime >= new Date().getTime();
  }

  listenToOtp(): Observable<{
    code: 'user-automatic-sign-in' | 'verification-code-received';
    verificationCode?: string;
    verificationId?: string;
  }> {
    return new Observable();
  }

  onSignIn(): EventEmitter<void> {
    return this._onSignIn$;
  }

  onSignOut(): EventEmitter<void> {
    return this._onSignOut$;
  }

  onUnroll(): EventEmitter<void> {
    return this._onUnroll$;
  }

  registerDevice(phoneNumber: string, pin: string): Observable<string> {
    return new Observable<string>((subscriber) => {
      setTimeout(() => subscriber.next('creating-device-on-server'), 900);
      setTimeout(async () => {
        try {
          let user = USERS_MOCKS.find((userMock) => userMock.phoneNumber === phoneNumber);
          if (user) {
            await this.mockSvc.updateUser(user.id, user);
          } else {
            user = {
              createdAt: moment().toDate(),
              email: null,
              firstName: null,
              id: faker.random.alphaNumeric(20),
              identityDocumentValidated: false,
              identityId: null,
              lastName: null,
              nationalId: null,
              phoneNumber,
              primaryAccountId: faker.random.alphaNumeric(20),
              profileId: null,
              uid: faker.random.alphaNumeric(32),
              updatedAt: moment().toDate()
            };
            await this.mockSvc.createUser(user);
          }
          await this.dataSvc.setPersistent(APP_USER_STORAGE_LABEL, user);
          USER_ID = user.id;
          this._userSubscriber.next(user);
          subscriber.next('finishing-authentication');
        } catch (error) {
          subscriber.error(error);
        }
      }, 1500);
      setTimeout(() => subscriber.next('setting-up-device'), 3000);
      setTimeout(async () => {
        await this.dataSvc.setPersistent(MOCK_LOGIN_PIN_STORAGE_LABEL, pin).catch(error => subscriber.error(error));
        subscriber.next('ready');
      }, 6000);
    });
  }

  async resendOtp(phoneNumber: string): Promise<void> {
    this.sendOtp(phoneNumber);
  }

  sendOtp(phoneNumber: string): Observable<{
    status: string;
    verificationId?: string;
    code?: string;
  }> {
    return new Observable((subscriber) => {
      setTimeout(() => {
        if (triggerRandomError(subscriber)) {
          return;
        }
        subscriber.next({ status: 'waiting-recaptcha-user-interaction' });
      }, 2000);
      setTimeout(() => {
        subscriber.next({ status: 'otp-sent' });
        subscriber.complete();
      }, 2500);
    });
  }

  setRecaptchaVerifierContainer(): void {
  }

  async signIn(pin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        if (pin === '0001') {
          return reject({ code: 'device-not-active', message: 'Device not active' });
        }
        const storedPin = await this.dataSvc.getPersistent(MOCK_LOGIN_PIN_STORAGE_LABEL);
        if (storedPin !== pin) {
          this._loginFailedAttempts++;
          if (this._loginFailedAttempts === 3) {
            this._loginFailedAttempts = 0;
            this.unroll();
            return reject({ code: 'device-blocked' , message: 'Device blocked' });
          }
          return reject({ code: 'wrong-password' , message: 'Wrong password' });
        }
        await this._startSession();
        this._onSignIn$.emit();
        resolve();
      }, 1000);
    });
  }

  async signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        clearTimeout(this._endSessionTimeout);
        await this.dataSvc.clearPersistent(APP_SESSION_END_TIME_STORAGE_LABEL);
        this._onSignOut$.emit();
        resolve();
      }, 2000);
    });
  }

  unroll(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        await this.dataSvc.clearAll();
        this._userSubscriber.next(null);
        USER_ID = null;
        this._onUnroll$.next();
        resolve();
      }, 2000);
    });
  }
}
