import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { triggerRandomError } from '@app/api/utils/mock/mock.util';
import { Charge } from '@resources/charges/charges.service';
import { CouponRedeem } from '@resources/coupon-redeems/coupon-redeems.service';
import { CouponTransfer } from '@app/api/resources/coupon-transfers/coupon-transfers.service';
import { Coupon } from '@app/api/resources/coupons/coupons.service';
import { Identity, IdentityDocumentValidation } from '@resources/identity/identity.service';
import { Profile } from '@resources/profile/profile.sevice';
import { User } from '@resources/user/user.service';
import { DataKey, DataService, MOCK_IDENTITY_DOCUMENT_VALIDATION_STORAGE_LABEL, MOCK_IDENTITY_STORAGE_LABEL, MOCK_PROFILE_STORAGE_LABEL, MOCK_USER_STORAGE_LABEL } from '@services/data/data.service';
import { CHARGES_MOCKS } from '@sources/mock/charges/charges.mock';
import { COUPON_REDEEMS_MOCKS } from '@sources/mock/coupon-redeems/coupon-redeems.mock';
import { COUPONS_TRANSFER_MOCKS } from '@sources/mock/coupon-transfer/coupon-transfer.mock';
import { COUPONS_MOCKS } from '@app/api/sources/mock/coupons/coupons.mock';
import { IDENTITIES_MOCKS, IDENTITY_DOCUMENT_VALIDATIONS_MOCKS } from '@sources/mock/identity/identities.mock';
import { PROFILES_MOCKS } from '@sources/mock/profile/profiles.mock';
import { USERS_MOCKS } from '@sources/mock/user/users.mock';

class Mock<T> {
  subject = new Subject<T[]>();
  data: T[] = [];
}

// tslint:disable-next-line:max-classes-per-file
@Injectable({
  providedIn: 'root'
})
export class MockService {
  private _chargesMock = new Mock<Charge>();
  private _couponsMock = new Mock<Coupon>();
  private _couponRedeemsMock = new Mock<CouponRedeem>();
  private _couponTransfersMock = new Mock<CouponTransfer>();
  private _identitiesMock = new Mock<Identity>();
  private _identityDocumentValidationsMock = new Mock<IdentityDocumentValidation>();
  private _profilesMock = new Mock<Profile>();
  private _usersMock = new Mock<User>();
  constructor(
    private dataSvc: DataService
  ) {
    this._initializeData();
  }

  createIdentity(data: any): Promise<string> {
    return this._create<Identity>(this._identitiesMock, data, { storageLabel: MOCK_IDENTITY_STORAGE_LABEL });
  }

  createIdentityDocumentValidation(data: any): Promise<string> {
    return this._create<IdentityDocumentValidation>(this._identityDocumentValidationsMock, data, { storageLabel: MOCK_IDENTITY_DOCUMENT_VALIDATION_STORAGE_LABEL });
  }

  createProfile(data: any): Promise<string> {
    return this._create<Profile>(this._profilesMock, data, { storageLabel: MOCK_PROFILE_STORAGE_LABEL });
  }

  createCouponRedeem(data: any): Promise<string> {
    return this._create<CouponRedeem>(this._couponRedeemsMock, data);
  }

  createUser(data: any): Promise<string> {
    return this._create<User>(this._usersMock, data, { storageLabel: MOCK_USER_STORAGE_LABEL });
  }

  createCouponTransfer(data: any): Promise<string> {
    return this._create<CouponTransfer>(this._couponTransfersMock, data);
  }

  getCharge(id: string): Observable<Charge> {
    return this._get<Charge>(this._chargesMock, id);
  }

  getCoupon(id: string): Observable<Coupon> {
    return this._get<Coupon>(this._couponsMock, id);
  }

  getCoupons(): Observable<Coupon[]> {
    return this._getAll<Coupon>(this._couponsMock);
  }

  getCouponTransfer(id: string): Observable<CouponTransfer> {
    return this._get<CouponTransfer>(this._couponTransfersMock, id);
  }

  getIdentity(id: string): Observable<Identity> {
    return this._get<Identity>(this._identitiesMock, id);
  }

  getIdentityDocumentValidation(id: string): Observable<IdentityDocumentValidation> {
    return this._get<IdentityDocumentValidation>(this._identityDocumentValidationsMock, id);
  }

  getCouponRedeem(id: string): Observable<CouponRedeem> {
    return this._get<CouponRedeem>(this._couponRedeemsMock, id);
  }

  getProfile(id: string): Observable<Profile> {
    return this._get<Profile>(this._profilesMock, id);
  }

  getUser(id: string): Observable<User> {
    return this._get<User>(this._usersMock, id);
  }

  updateCoupon(id: string, data: any): Promise<void> {
    return this._update<Coupon>(this._couponsMock, id, data);
  }

  updateCouponTransfer(id: string, data: any): Promise<void> {
    return this._update<CouponTransfer>(this._couponTransfersMock, id, data);
  }

  updateIdentity(id: string, data: any): Promise<void> {
    return this._update<Identity>(this._identitiesMock, id, data, { storageLabel: MOCK_IDENTITY_STORAGE_LABEL });
  }

  updateIdentityDocumentValidation(id: string, data: any): Promise<void> {
    return this._update<IdentityDocumentValidation>(this._identityDocumentValidationsMock, id, data, { storageLabel: MOCK_IDENTITY_DOCUMENT_VALIDATION_STORAGE_LABEL });
  }

  updateCouponRedeem(id: string, data: any): Promise<void> {
    return this._update<CouponRedeem>(this._couponRedeemsMock, id, data);
  }

  updateProfile(id: string, data: any): Promise<void> {
    return this._update<Profile>(this._profilesMock, id, data, { storageLabel: MOCK_PROFILE_STORAGE_LABEL });
  }

  updateUser(id: string, data: any): Promise<void> {
    return this._update<User>(this._usersMock, id, data, { storageLabel: MOCK_USER_STORAGE_LABEL });
  }

  private _create<T>(mock: Mock<T>, data: any, persist?: { storageLabel: DataKey }): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        let found = false;
        for (let i = 0; i < mock.data.length; i++) {
          if ((mock.data[i] as any).id === data.id) {
            mock.data[i] = { ...mock.data[i], ...data };
            found = true;
            break;
          }
        }
        if (!found) {
          mock.data.push(data);
        }
        if (persist) {
          await this.dataSvc.setPersistent(persist.storageLabel, mock.data);
        }
        this._triggerChange(mock, 0);
        resolve(data.id);
      }, 1000);
    });
  }

  private _get<T>(mock: Mock<T>, id: string): Observable<T> {
    return this._getAll(mock).pipe(map((values) => {
      return values.find((value: any) => value.id === id);
    }));
  }

  private _getAll<T>(mock: Mock<T>): Observable<T[]> {
    this._triggerChange(mock);
    return new Observable((subscriber) => mock.subject.subscribe((values) => {
      if (triggerRandomError(subscriber)) {
        return;
      }
      subscriber.next(values);
    }));
  }

  private async _initializeData(): Promise<void> {
    this._chargesMock.data = CHARGES_MOCKS;
    this._couponsMock.data = COUPONS_MOCKS;
    this._couponRedeemsMock.data = COUPON_REDEEMS_MOCKS;
    this._couponTransfersMock.data = COUPONS_TRANSFER_MOCKS;
    this._identitiesMock.data = this._mergeEntitiesList(IDENTITIES_MOCKS, await this.dataSvc.getPersistent<Identity[]>(MOCK_IDENTITY_STORAGE_LABEL));
    this._identityDocumentValidationsMock.data = this._mergeEntitiesList(IDENTITY_DOCUMENT_VALIDATIONS_MOCKS, await this.dataSvc.getPersistent<IdentityDocumentValidation[]>(MOCK_IDENTITY_DOCUMENT_VALIDATION_STORAGE_LABEL));
    this._profilesMock.data = this._mergeEntitiesList(PROFILES_MOCKS, await this.dataSvc.getPersistent<Profile[]>(MOCK_PROFILE_STORAGE_LABEL));
    this._usersMock.data = this._mergeEntitiesList(USERS_MOCKS, await this.dataSvc.getPersistent<User[]>(MOCK_USER_STORAGE_LABEL));
  }

  private _mergeEntitiesList<T>(listA: T[], listB: T[]): T[] {
    const mergedList = {};
    if (listA) {
      listA.forEach((elementA) => {
        if (elementA) {
          mergedList[(elementA as any).id] = elementA;
        }
      });
    }
    if (listB) {
      listB.forEach((elementB) => {
        if (elementB) {
          mergedList[(elementB as any).id] = elementB;
        }
      });
    }
    return Object.keys(mergedList).map((id) => {
      return mergedList[id];
    });
  }

  private _triggerChange<T>(mock: Mock<T>, timeout = 1000): void {
    setTimeout(() => mock.subject.next(mock.data), timeout);
  }

  private _update<T>(mock: Mock<T>, id: string, data: any, persist?: { storageLabel: DataKey }): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (triggerRandomError(reject)) {
          return;
        }
        let found = false;
        for (let i = 0; 0 < mock.data.length; i++) {
          if ((mock.data[i] as any).id === id) {
            mock.data[i] = { ...mock.data[i], ...data };
            found = true;
            break;
          }
        }
        if (!found) {
          throw new Error(`Entity ${id} not found.`);
        }
        if (persist) {
          await this.dataSvc.setPersistent(persist.storageLabel, mock.data);
        }
        this._triggerChange(mock, 0);
        resolve();
      }, 1000);
    });
  }
}
