import { Coupon } from '@app/api/resources/coupons/coupons.service';
import { IDENTITY_VALIDATED_WITH_COUPONS_USER_1, IDENTITY_VALIDATED_WITH_COUPONS_USER_2, MAIN_USER } from '@sources/mock/user/users.mock';
import * as moment from 'moment';

export const COUPON_ACTIVE: Coupon = {
  additionalData: null,
  amount: 5,
  availableAmount: 5,
  createdAt: moment().toDate(),
  currency: 'ABP',
  currentUserId: MAIN_USER.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: 'VSFDjKACfcReDlOoOuhw',
  image: 'assets/img/cylinder-5.png',
  lastTransferAt: null,
  lastTransferId: null,
  material: 'GAS05N',
  previousUserId: null,
  previousUserInfo: null,
  redeemedAt: null,
  redeemsInfo: null,
  status: 'active',
  statusData: null,
  transferable: true,
  updatedAt: moment().toDate(),
};

export const COUPON_LOST: Coupon = {
  additionalData: null,
  amount: 11,
  availableAmount: 11,
  createdAt: moment().subtract(10, 'd').toDate(),
  currency: 'ABP',
  currentUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.id,
  destinationUserInfo: null,
  expiresAt: moment().add(2, 'M').toDate(),
  id: 'bWsk9PubJjyCzFgEjObW',
  image: 'assets/img/cylinder-11.png',
  lastTransferAt: moment().subtract(10, 'd').toDate(),
  lastTransferId: 'yWdv5GxhlV5QeSKgRMIl',
  material: 'GAS11N',
  previousUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
  previousUserInfo: {
    firstName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.firstName,
    lastName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.lastName,
  },
  redeemedAt: null,
  redeemsInfo: null,
  status: 'active',
  statusData: null,
  transferable: true,
  updatedAt: moment().subtract(10, 'd').toDate(),
};

export const COUPON_NON_TRANSFERABLE: Coupon = {
  additionalData: {
    campaign: 'municipalidades'
  },
  amount: 15,
  availableAmount: 15,
  createdAt: moment().toDate(),
  currency: 'ABP',
  currentUserId: MAIN_USER.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: 'X4vNK0vQytPbe0oIH3m9',
  image: 'assets/img/cylinder-15.png',
  lastTransferAt: null,
  lastTransferId: null,
  material: 'GAS15N',
  previousUserId: null,
  previousUserInfo: null,
  redeemedAt: null,
  redeemsInfo: null,
  status: 'active',
  statusData: null,
  transferable: false,
  updatedAt: moment().toDate(),
};

export const COUPON_SENT_BY_LINK: Coupon = {
  additionalData: null,
  amount: 11,
  availableAmount: 11,
  createdAt: moment().subtract(7, 'd').toDate(),
  currency: 'ABP',
  currentUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: '8YiaGKtIWJINeiJSTnwR',
  image: 'assets/img/cylinder-11.png',
  lastTransferAt: moment().toDate(),
  lastTransferId: 'JJiaGKtIWJINenwRiJST',
  material: 'GAS11N',
  previousUserId: null,
  previousUserInfo: null,
  redeemedAt: null,
  redeemsInfo: null,
  status: 'sent',
  statusData: null,
  transferable: true,
  updatedAt: moment().toDate(),
};

export const COUPON_TRANSFERRED: Coupon = {
  additionalData: null,
  amount: 15,
  availableAmount: 15,
  createdAt: moment().subtract(7, 'd').toDate(),
  currency: 'ABP',
  currentUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: 'tBRFy2vwIDNwXxLUVmIC',
  image: 'assets/img/cylinder-15.png',
  lastTransferAt: moment().subtract(1, 'd').toDate(),
  lastTransferId: 'KdN0GFCl9Pou30Sufpva',
  material: 'GAS15N',
  previousUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
  previousUserInfo: {
    firstName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.firstName,
    lastName: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.lastName,
  },
  redeemedAt: null,
  redeemsInfo: null,
  status: 'active',
  statusData: null,
  transferable: true,
  updatedAt: moment().subtract(1, 'd').toDate(),
};

export const COUPON_REDEEMED: Coupon = {
  additionalData: null,
  amount: 45,
  availableAmount: 45,
  createdAt: moment().subtract(7, 'd').toDate(),
  currency: 'ABP',
  currentUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: 'oiWAgkmK6AmNOMgABTuL',
  image: 'assets/img/cylinder-45.png',
  lastTransferAt: null,
  lastTransferId: null,
  material: 'GAS45N',
  previousUserId: null,
  previousUserInfo: null,
  redeemedAt: moment().subtract(2, 'd').toDate(),
  redeemsInfo: {
    'LfCnmpimBNNyJH7WjSn': {
      amount: 45,
      createdAt: moment().subtract(2, 'd').toDate()
    }
  },
  status: 'redeemed',
  statusData: null,
  transferable: true,
  updatedAt: moment().subtract(2, 'd').toDate(),
};

export const COUPON_REDEEM_ALWAYS_FAILS: Coupon = {
  additionalData: null,
  amount: 5,
  availableAmount: 5,
  createdAt: moment().toDate(),
  currency: 'ABP',
  currentUserId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
  destinationUserInfo: null,
  expiresAt: moment().add(1, 'M').toDate(),
  id: 'VSFDjKACfAmNOMgABTuL',
  image: 'assets/img/cylinder-5.png',
  lastTransferAt: null,
  lastTransferId: null,
  material: 'GAS05N',
  previousUserId: null,
  previousUserInfo: null,
  redeemedAt: null,
  redeemsInfo: null,
  status: 'active',
  statusData: null,
  transferable: true,
  updatedAt: moment().toDate(),
};

export const COUPONS_MOCKS: Coupon[] = [
  COUPON_ACTIVE,
  COUPON_LOST,
  COUPON_NON_TRANSFERABLE,
  COUPON_SENT_BY_LINK,
  COUPON_TRANSFERRED,
  COUPON_REDEEMED,
  COUPON_REDEEM_ALWAYS_FAILS
];
