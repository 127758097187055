import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';

import firebase from 'firebase/app';
import 'firebase/messaging';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ENVIRONMENT } from '@app/config';
import { AuthService, Session, SessionBase } from '@resources/auth/auth.service';
import { FirestoreService } from '@sources/firebase/firestore.service';
import { NotificationsServiceInterface } from '../notifications/notifications.service';

const COLLECTION_PATH = 'fcmTokens';
const GRANTED_NOTIFICATION_PERMISSION = 'granted';
const PERMISSION_DENIED_CODE = 'permission-denied';

@Injectable()
export class FcmService implements NotificationsServiceInterface {
  private _currentToken: string;
  private _initialized: boolean;
  private _isNativePlatform: boolean;
  private _tokenPendingToRegister: string;

  constructor(
    private authSvc: AuthService,
    private firestoreSvc: FirestoreService,
    private platform: Platform

  ) {
    this._isNativePlatform = this.platform.is('capacitor');
    const step = 'on-sign-in';
    this.authSvc.onSignIn()
      .subscribe(() => {
        if (this._initialized && this._tokenPendingToRegister) {
          this._updateToken(this._tokenPendingToRegister)
            .catch((error: any) => {
              console.warn(`Error in FcmService.constructor() at step ${step}.`, error);
            });
        }
      });
  }

  public async init(): Promise<void> {
    const hasPermission = await this._requestPermission();
    if (hasPermission) {
      this._initTokenListener();
    }
  }

  public listenToNotifications(): Observable<any> {
    return new Observable((subscriber) => {
      if (this._isNativePlatform) {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
          subscriber.next(notification);
        });
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
          subscriber.next(notification);
        });
      } else {
        firebase.messaging().onMessage((payload) => subscriber.next(payload.notification));
      }
    });
  }

  public async removeToken(): Promise<void> {
    const token = this._currentToken ?? this._tokenPendingToRegister;
    if (!token) {
      return Promise.resolve();
    }
    const session: Session = await this.authSvc
      .getSession()
      .pipe(take(1))
      .toPromise()
      .catch(() => null);
    if (!session) {
      return Promise.reject('no user session');
    }
    await this.firestoreSvc.delete(`${COLLECTION_PATH}/${token}`)
      .catch((error) => {
        console.error('Error deleting fcm token');
        return Promise.reject(error);
      });
    this._currentToken = null;
    this._tokenPendingToRegister = null;
  }

  private _errorSetToken(error: any, token: string): void {
    let step = 'error-set-token';
    if (this._isErrorPermissionDenied(error)) { // This is the case when the token exist but its taken by another user
      step = 'token-taken';
      console.warn('fcm token taken by another user', token);
    }
    console.error(`Error in FcmService._errorSetToken() at step ${step}.`, error);
  }

  private async _errorUpdateToken(error: any, session: SessionBase, token: string) {
    let step = 'error-update-token';
    if (this._isErrorPermissionDenied(error)) { // This means that the document doesn't exist
      step = 'set-token';
      return await this._setToken(session, token);
    }
    console.error(`Error in FcmService._errorUpdateToken() at step ${step}.`, error);
  }
  private async _initTokenListener(): Promise<void> {
    if (this._isNativePlatform) {
      await PushNotifications.register();
      PushNotifications.addListener('registration',
        async (token: Token) => {
          await this._updateToken(token.value);
        }
      );
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.error('Registration error in FcmService._initTokenListener,', error);
        }
      );
    } else {
      const token = await firebase.messaging().getToken({ vapidKey: ENVIRONMENT.appPlatform.firebaseConfig.vapidKey })
      .catch((error) => {
        console.error('Error getting firebase messaging token', error);
        return Promise.reject(error);
      });
      await this._updateToken(token);
    }
    this._initialized = true;
  }

  private _isErrorPermissionDenied(error: any) {
    return error.code && error.code === PERMISSION_DENIED_CODE;
  }

  private async _requestPermission(): Promise<boolean> {
    let step = '';
    try {
      if (this._isNativePlatform) {
        step = 'check-permissions-native';
        const { receive } = await PushNotifications.checkPermissions();
        const hasPermission = receive === GRANTED_NOTIFICATION_PERMISSION;
        if (!hasPermission) {
          step = 'request-permissions-native';
          const result = await PushNotifications.requestPermissions();
          if (result.receive !== GRANTED_NOTIFICATION_PERMISSION) {
            return false;
          }
        }
        return true;
      } else {
        const hasPermission = await Notification.requestPermission();
        return hasPermission === GRANTED_NOTIFICATION_PERMISSION;
      }
    } catch (error) {
      console.error(`Error in FcmService._requestPermission() at step ${step}.`, error);
    }
  }

  private _setToken(session: SessionBase, token: string) {
    return this.firestoreSvc
      .set(`${COLLECTION_PATH}/${token}`, session)
      .then(() => {
        this._tokenPendingToRegister = null;
        this._currentToken = token;
      })
      .catch((error: any) => this._errorSetToken(error, token));
  }

  private async _updateToken(token: string): Promise<void> {
    const session: Session = await this.authSvc
      .getSession()
      .pipe(take(1))
      .toPromise()
      .catch(() => null);
    if (!session) {
      this._tokenPendingToRegister = token;
      return Promise.resolve();
    }
    const { deviceId, userId } = session;
    const sessionBase: SessionBase = { deviceId, userId };
    await this.firestoreSvc
      .update(`${COLLECTION_PATH}/${token}`, sessionBase)
      .catch((error: any) => this._errorUpdateToken(error, sessionBase, token));
    this._tokenPendingToRegister = null;
  }
}
