import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PrivacyPolicyPage } from '@app/pages/privacy-policy/privacy-policy.page';

const routes: Routes = [
  {
    path: 'enrollment',
    loadChildren: () => import('./pages/enrollment/enrollment.module').then(m => m.EnrollmentPagesModule)
  },
  {
    path: 'enrolled',
    loadChildren: () => import('./pages/enrolled/enrolled.module').then(m => m.EnrolledPagesModule)
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage
  },
  { path: '', redirectTo: '/enrolled/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
