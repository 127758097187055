import * as faker from 'faker';
import * as moment from 'moment';

import { User } from '@resources/user/user.service';

export const MAIN_USER: User = {
  createdAt: moment().subtract(2, 'M').toDate(),
  email: null,
  firstName: null,
  id: 'IabycUfRVXmxmVrjWbSh',
  identityDocumentValidated: false,
  identityId: null,
  lastName: null,
  nationalId: null,
  phoneNumber: '+56912345678',
  primaryAccountId: faker.random.alphaNumeric(20),
  profileId: null,
  uid: faker.random.alphaNumeric(32),
  updatedAt: moment().subtract(1, 'M').toDate()
};

export const IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1: User = {
  createdAt: moment().subtract(1, 'M').toDate(),
  email: 'fcastillo@gmail.com',
  firstName: 'Fernanda',
  id: 'AMu486CknbovAgoadGan',
  identityDocumentValidated: true,
  identityId: 'nyWdtyDAInMNCLrDVEpS',
  lastName: 'Castillo',
  nationalId: '171829208',
  phoneNumber: '+56911111111',
  primaryAccountId: faker.random.alphaNumeric(20),
  profileId: 'vVLmzAbKjMtGCFTiMNBQ',
  uid: faker.random.alphaNumeric(32),
  updatedAt: moment().subtract(3, 'd').toDate()
};

export const IDENTITY_VALIDATED_WITH_COUPONS_USER_1: User = {
  createdAt: moment().subtract(15, 'd').toDate(),
  email: 'csoto@gmail.com',
  firstName: 'Carlos',
  id: 'JIuriACknbovAgoadGan',
  identityDocumentValidated: true,
  identityId: 'sughMOrFiKJTRpjdyNNs',
  lastName: 'Soto',
  nationalId: '197015896',
  phoneNumber: '+56922222222',
  primaryAccountId: faker.random.alphaNumeric(20),
  profileId: 'hTtOQimZRJRRYMaJZJWM',
  uid: faker.random.alphaNumeric(32),
  updatedAt: moment().subtract(7, 'd').toDate()
};

export const IDENTITY_VALIDATED_WITH_COUPONS_USER_2: User = {
  createdAt: moment().subtract(3, 'd').toDate(),
  email: 'tomas.demaria86@gmail.com',
  firstName: 'Tomás',
  id: 'sBOpUjjoZeezUdEdOAbp',
  identityDocumentValidated: true,
  identityId: 'XqmxbuVIwvLrtSqLhDIn',
  lastName: 'Demaria',
  nationalId: '157193317',
  phoneNumber: '+56933333333',
  primaryAccountId: faker.random.alphaNumeric(20),
  profileId: 'QXarebnvDmsahhEnYWTS',
  uid: faker.random.alphaNumeric(32),
  updatedAt: moment().subtract(3, 'd').toDate()
}

export const USERS_VALIDATED: User[] = [
  IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1,
  IDENTITY_VALIDATED_WITH_COUPONS_USER_1,
  IDENTITY_VALIDATED_WITH_COUPONS_USER_2
];

export const USERS_MOCKS: User[] = [
  MAIN_USER,
  ...USERS_VALIDATED
];
