import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import * as Bowser from 'bowser';
import { v4 as UUID } from 'uuid';

import { APP_UUID_STORAGE_LABEL, DataService } from '@services/data/data.service';

declare const GooglePlayServicesChecker: any;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private _alias: string;
  private _browser: Bowser.Parser.ParsedResult;
  private _manufacturer: string;
  private _model: string;
  private _os: string;
  private _platform: string;
  private _serial: string;
  private _uuid: string;
  private _version: string;
  constructor(
    private dataSvc: DataService,
    private platformPlugin: Platform
  ) {
    this._browser = Bowser.parse(window.navigator.userAgent);
    this._setAlias();
    this._setManufacturer();
    this._setModel();
    this._setOS();
    this._setPlatform();
    this._setSerial();
    this._setUUID();
    this._setVersion();
  }

  public get alias(): string { return this._alias; }
  public get manufacturer(): string { return this._manufacturer; }
  public get model(): string { return this._model; }
  public get os(): string { return this._os; }
  public get platform(): string { return this._platform; }
  public get serial(): string { return this._serial; }
  public get uuid(): string { return this._uuid; }
  public get version(): string { return this._version; }

  private _setAlias(): void {
    // TODO: add ionic-native device support
    if (this._browser) {
      if (this._browser.platform) {
        if (this._browser.platform.type === 'mobile') {
          if (this._browser.platform.model) {
            this._alias = this._browser.platform.model;
          }
          if (this._browser.os && this._browser.os.name && this._browser.os.version) {
            this._alias = this._alias ? `${this._alias} ` : '';
            this._alias += `${this._browser.os.name} ${this._browser.os.version}`;
          }
        } else if (this._browser.os && this._browser.os.name && this._browser.os.version) {
          this._alias = `${this._browser.os.name} ${this._browser.os.version}`;
          if (this._browser.platform.vendor) {
            this._alias = `${this._browser.platform.vendor} ${this._alias}`;
          }
        }
      }
    }
    this._alias = this._alias || 'Unknown';
  }

  private _setManufacturer(): void {
    // TODO: add ionic-native device support
    if (this._browser) {
      if (this._browser.platform && this._browser.platform.vendor) {
        this._manufacturer = this._browser.platform.vendor;
      } else if (this._browser.os && this._browser.os.name) {
        this._manufacturer = this._browser.os.name;
      }
    }
    this._manufacturer = this._manufacturer || 'Unknown';
  }

  private _setModel(): void {
    // TODO: add ionic-native device support
    if (this._browser) {
      if (this._browser.platform && this._browser.platform.vendor && this._browser.platform.model) {
        this._model = `${this._browser.platform.vendor} ${this._browser.platform.model}`;
      } else if (this._browser.os && this._browser.os.name) {
        this._model = `${this._browser.os.name}`;
        if (this._browser.os.versionName) {
          this._model += ` ${this._browser.os.versionName}`;
        }
      }
    }
    this._model = this._model || 'Unknown';
  }

  private _setOS(): void {
    // TODO: add ionic-native device support
    if (this._browser && this._browser.os && this._browser.os.name) {
      this._os = this._browser.os.name;
    }
    this._os = this._os || 'Unknown';
  }

  private _setPlatform(): void {
    // TODO: add ionic-native device support
    if (this._browser && this._browser.platform && this._browser.platform.type) {
      this._platform = this._browser.platform.type;
    }
    this._platform = this._platform || 'unknown';
  }

  private _setSerial(): void {
    // TODO: add ionic-native device support
    if (this._browser) {
      Object.keys(this._browser).forEach((info) => {
        Object.keys(this._browser[info]).forEach((param) => {
          this._serial = (this._serial ? `${this._serial}_` : '') + this._browser[info][param];
        });
      });
    }
    this._serial = this._serial || 'Unknown';
  }

  private async _setUUID(): Promise<void> {
    // TODO: add ionic-native device support
    let step = '', uuid;
    try {
      step = 'get-uuid-from-persistent';
      uuid = await this.dataSvc.getPersistent<string>(APP_UUID_STORAGE_LABEL);
      if (!uuid) {
        step = 'generate-uuid';
        const tempUUID = UUID();
        step = 'save-uuid-to-persistent';
        await this.dataSvc.setPersistent(APP_UUID_STORAGE_LABEL, tempUUID);
        uuid = tempUUID;
      }
    } catch (error) {
      console.error(`Error in DeviceService._setUUID() at step ${step}`, error);
    } finally {
      // not the best unique-generated uuid in case of a failure but we need something
      this._uuid = uuid || `unknown-${new Date().getTime()}`;
    }
  }

  private _setVersion(): void {
    // TODO: add ionic-native device support
    if (this._browser && this._browser.os && this._browser.os.version) {
      this._version = this._browser.os.version;
    }
    this._version = this._version || 'unknown';
  }

  public isGooglePlayServicesAvailable(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.platformPlugin.is('android')) {
        if (typeof GooglePlayServicesChecker !== 'undefined') {
          GooglePlayServicesChecker.check((result) => resolve(result.status), (error) => reject(error));
        } else {
          resolve(false);
        }
        return;
      }
      // this function should only be called on native mode, we only
      // need to check Google Play Services on Android platform
      resolve(true);
    });
  }
}
