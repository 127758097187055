import { AccountManagerServiceInterface } from '@services/account-manager/account-manager.service';

export class AccountManagerServiceMock implements AccountManagerServiceInterface {

  addAccount(username: string, password: string): Promise<void> {
    return Promise.resolve();
  }

  getUserAccount(): Promise<any> {
    return Promise.resolve({ uid:  '+56912345678'});
  }
  getDataFromKey(key: string): Promise<string> {
    let value = null;
    switch (key) {
      case 'id':
        value = 'qnMnNapXUvN0qQyZ';
        break;

      case 'sharedKey':
        value = '788f35c01ecde2427cf53562fe647a8bdd172cc277305aad9fbc94ba3564da00';
        break;
    }
    return Promise.resolve(value);
  }

  getPassword(): Promise<string> {
    return Promise.resolve('123456');
  }

  removeAccount(): Promise<void> {
    return Promise.resolve();
  }

  setUserData(userData: any): Promise<void> {
    return Promise.resolve();
  }
}
