// copy of firebase.firestore.WhereFilterOp
export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'array-contains-any'
    | 'not-in';
export interface QueryConfig {
  [field: string]: boolean | null | number | string | QueryFieldConfig | QueryFieldConfig[];
}
export interface QueryFieldConfig {
  whereFilter: WhereFilterOp;

  value: any;
}
export interface QueryOptions {
  orderBy?: string,
  direction?: 'desc' | 'asc',
  limit?: number
}
export function changeTimestampsToDate(obj: any): any {
  const changedObject = obj.constructor.name === 'Array' ? [...obj] :  {...obj};
  for (const keyOrIndex in obj) {
    const item = obj[keyOrIndex];
    if (item && typeof item === 'object') {
      if (typeof item.toDate === 'function') {
        changedObject[keyOrIndex] = item.toDate();
      } else {
        changedObject[keyOrIndex] = changeTimestampsToDate(item);
      }
    }
  }
  return changedObject;
}
export function configQueryReference(ref: any, queryConfig: QueryConfig, options?: QueryOptions) {
  Object.keys(queryConfig).forEach((key) => {
    if (typeof queryConfig[key] === 'object') {
      if (Array.isArray(queryConfig)) {
        for (const queryFieldConfig of (queryConfig as QueryFieldConfig[])[key]) {
          ref = ref.where(key, queryFieldConfig.whereFilter, queryFieldConfig.value)
        }
      } else {
        ref = ref.where(key, (queryConfig[key] as QueryFieldConfig).whereFilter, (queryConfig[key] as QueryFieldConfig).value)
      }
    } else {
      ref = ref.where(key, '==', queryConfig[key]);
    }
    if (options) {
      if (options.orderBy) {
        ref = ref.orderBy(options.orderBy, options.direction ?? 'asc');
      }
      if (options.limit) {
        ref = ref.limit(options.limit);
      }
    }
  });
  return ref;
}
