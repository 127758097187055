import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';

import { NotificationsServiceInterface } from './notifications.service';

@Injectable()
export class NotificationsServiceMock implements NotificationsServiceInterface {
  private notificationSubscriber: Subscriber<any>;

  public init(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  public listenToNotifications(): Observable<any> {
    return new Observable((subscriber) => {
      this.notificationSubscriber = subscriber;
    });
  }

  public removeToken(): Promise<void> {
    return Promise.resolve();
  }
}
