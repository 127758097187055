import * as moment from 'moment';

import { Identity, IdentityDocumentValidation } from '@resources/identity/identity.service';
import { IDENTITY_VALIDATED_WITH_COUPONS_USER_1, IDENTITY_VALIDATED_WITH_COUPONS_USER_2, IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1 } from '@sources/mock/user/users.mock';

const dateTracker = moment();
export const IDENTITIES_MOCKS: Identity[] = [
  {
    createdAt: dateTracker.toDate(),
    id: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.identityId,
    identityDocumentValidationId: 'PVWfPLRxgkabtzyajEiV',
    nationalId: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.nationalId,
    needsRevision: false,
    revisionReason: null,
    updatedAt: dateTracker.toDate(),
    userId: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.id,
    validated: true
  },
  {
    createdAt: dateTracker.toDate(),
    id: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.identityId,
    identityDocumentValidationId: 'AMBIZNgqcySUKQjaGCxO',
    nationalId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.nationalId,
    needsRevision: false,
    revisionReason: null,
    updatedAt: dateTracker.toDate(),
    userId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.id,
    validated: true
  },
  {
    createdAt: dateTracker.toDate(),
    id: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.identityId,
    identityDocumentValidationId: 'XqmxbuVIwvLrtSqLhDIn',
    nationalId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.nationalId,
    needsRevision: false,
    revisionReason: null,
    updatedAt: dateTracker.toDate(),
    userId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.id,
    validated: true
  }
];

export const IDENTITY_DOCUMENT_VALIDATIONS_MOCKS: IdentityDocumentValidation[] = [
  {
    createdAt: dateTracker.toDate(),
    documentId: '111111111',
    id: 'PVWfPLRxgkabtzyajEiV',
    nationalId: IDENTITY_VALIDATED_WITHOUT_COUPONS_USER_1.nationalId,
    status: 'done',
    statusData: null,
    valid: true,
    updatedAt: dateTracker.toDate()
  },
  {
    createdAt: dateTracker.toDate(),
    documentId: '222222222',
    id: 'AMBIZNgqcySUKQjaGCxO',
    nationalId: IDENTITY_VALIDATED_WITH_COUPONS_USER_1.nationalId,
    status: 'done',
    statusData: null,
    valid: true,
    updatedAt: dateTracker.toDate()
  },
  {
    createdAt: dateTracker.toDate(),
    documentId: '333333333',
    id: 'XqmxbuVIwvLrtSqLhDIn',
    nationalId: IDENTITY_VALIDATED_WITH_COUPONS_USER_2.nationalId,
    status: 'done',
    statusData: null,
    valid: true,
    updatedAt: dateTracker.toDate()
  }
];
