import { Charge } from '@app/api/resources/charges/charges.service';
import * as moment from 'moment';

export const CHARGE_WITH_AMOUNT_5: Charge = {
  additionalData: {
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6',
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    storeId: 'LfCnmpimBNggOYnqv2ED',
    userId: 'XDbNBLN5ek9kM9P2Brzo'
  },
  amount: 5,
  createdAt: moment().toDate(),
  currency: 'ABP',
  id: '1',
  rejectedAt: null,
  status: 'pending',
  statusData: null,
  updatedAt: moment().toDate(),
  voidedAt: null,
};

export const CHARGE_WITH_AMOUNT_11: Charge = {
    additionalData: {
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6',
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    storeId: 'LfCnmpimBNggOYnqv2ED',
    userId: 'XDbNBLN5ek9kM9P2Brzo'
  },
  amount: 11,
  createdAt: moment().toDate(),
  currency: 'ABP',
  id: '2',
  rejectedAt: null,
  status: 'pending',
  statusData: null,
  updatedAt: moment().toDate(),
  voidedAt: null,
};

export const CHARGE_WITH_AMOUNT_15: Charge = {
    additionalData: {
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6',
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    storeId: 'LfCnmpimBNggOYnqv2ED',
    userId: 'XDbNBLN5ek9kM9P2Brzo'
  },
  amount: 15,
  createdAt: moment().toDate(),
  currency: 'ABP',
  id: '3',
  rejectedAt: null,
  status: 'pending',
  statusData: null,
  updatedAt: moment().toDate(),
  voidedAt: null,
};

export const CHARGE_WITH_AMOUNT_45: Charge = {
    additionalData: {
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6',
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    storeId: 'LfCnmpimBNggOYnqv2ED',
    userId: 'XDbNBLN5ek9kM9P2Brzo'
  },
  amount: 45,
  createdAt: moment().toDate(),
  currency: 'ABP',
  id: '4',
  rejectedAt: null,
  status: 'pending',
  statusData: null,
  updatedAt: moment().toDate(),
  voidedAt: null,
};

export const CHARGE_WITH_AMOUNT_15_AND_DIFFERENT_CURRENCY: Charge = {
    additionalData: {
    pointOfSalesId: 'NyJH7WjSneHXH5CS3oc6',
    pointOfSalesInfo: {
      name: 'Camión 1'
    },
    storeId: 'LfCnmpimBNggOYnqv2ED',
    userId: 'XDbNBLN5ek9kM9P2Brzo'
  },
  amount: 15,
  createdAt: moment().toDate(),
  currency: 'CLP',
  id: '5',
  rejectedAt: null,
  status: 'pending',
  statusData: null,
  updatedAt: moment().toDate(),
  voidedAt: null,
};
export const CHARGES_MOCKS: Charge[] = [
  CHARGE_WITH_AMOUNT_5,
  CHARGE_WITH_AMOUNT_11,
  CHARGE_WITH_AMOUNT_15,
  CHARGE_WITH_AMOUNT_45,
  CHARGE_WITH_AMOUNT_15_AND_DIFFERENT_CURRENCY
];